import React, { useState } from 'react';
import {
  Layout,
  Dropdown,
  Avatar,
  Typography,
  Space,
  Badge,
  Button,
  Popover,
  Menu,
  Drawer,
  Tag,
} from 'antd';
import {
  BellOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MailFilled,
  MenuOutlined,
  PoweroffOutlined,
  SketchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Link, useHistory } from 'react-router-dom';
import { useToolsStore } from '../../context/Tools';
import { logout } from '../../tools/Tools';
import Notifications from './components/notification/Notifications';
import { PORTAL_URL } from '../../constants/SystemConstants';
import MenuSide from '../menu/MenuSide';
import { accessLogout } from '../../service/userService';

const { Header } = Layout;
const { Text } = Typography;

export default function PageHeader({
  menus,
  collapsed,
  hideCollapseIcon,
  setCollapsed,
}) {
  const { keycloak } = useKeycloak();
  const {
    setterSelectedUser,
    org,
    letterCount,
    notificationCount,
    selectedUser,
  } = useToolsStore();

  const history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleHelpPage = () => {
    history.push('/categoryList');
  };

  const handleMessagePage = () => {
    history.push('/letter');
  };

  const handleHomepage = () => {
    const win = window.open(PORTAL_URL);
    win.focus();
  };

  const showMenuDrawer = () => {
    setOpenMenu(true);
  };

  const logoutAccess = () => {
    const val = {
      register: keycloak?.tokenParsed?.preferred_username,
      sessionState: keycloak?.tokenParsed?.session_state,
    };
    accessLogout(val).finally(() => logout(keycloak));
  };

  const menu = (
    <Menu className="app-header-dropdown">
      {window.location.pathname !== '/checkRegister' && (
        <Menu.Item key="users">
          <Link to="/checkRegister" onClick={() => setterSelectedUser(null)}>
            <Button type="link" icon={<UserOutlined />}>
              Хэрэглэгчийн эрхүүд
            </Button>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="logout">
        <Link to="/" onClick={() => logoutAccess()}>
          <Button type="link" icon={<PoweroffOutlined />}>
            Гарах
          </Button>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header>
      {!hideCollapseIcon && (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          {collapsed ? (
            <DoubleRightOutlined
              className="header-icon-right"
              onClick={toggle}
            />
          ) : (
            <DoubleLeftOutlined
              className="header-icon-right"
              onClick={toggle}
            />
          )}
          <MenuOutlined className="menu-show" onClick={showMenuDrawer} />
          {org && (
            <span style={{ marginLeft: 12, marginRight: 8 }}>
              {org.name}
              {org.registerNo ? ` (${org.registerNo})` : ''}
            </span>
          )}
          {selectedUser && <Tag color="blue">{selectedUser?.userRoleName}</Tag>}
        </div>
      )}
      <div className="top-menu-link">
        {!hideCollapseIcon && (
          <Space size={0} style={{ marginRight: 20 }}>
            <Text className="list-inline-item" onClick={handleMessagePage}>
              <Badge count={letterCount}>
                <Button type="text" className="helpText" icon={<MailFilled />}>
                  <span className="letter-top">Захидал</span>
                </Button>
              </Badge>
            </Text>
            <Text
              className="list-inline-item help-top"
              onClick={handleHelpPage}
            >
              Тусламж
            </Text>
            <Popover
              placement="bottom"
              content={<Notifications />}
              trigger="click"
              className="list-inline-item"
            >
              <Badge count={notificationCount?.totalQuantity} offset={[-12, 9]}>
                <BellOutlined />
              </Badge>
            </Popover>
            <Text className="list-inline-item" onClick={handleHomepage}>
              <SketchOutlined />
            </Text>
          </Space>
        )}
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <li className="list-inline-item">
            <Avatar icon={<UserOutlined />} />
            <span className="avatar-text">
              {keycloak?.tokenParsed?.given_name}
            </span>
          </li>
        </Dropdown>
        <Button
          id="hiddenLogoutBtn"
          style={{ display: 'none' }}
          onClick={() => logoutAccess()}
        />
      </div>
      <Drawer
        closable={false}
        placement="left"
        className="menu-drawer"
        onClose={() => setOpenMenu(false)}
        visible={openMenu}
      >
        <MenuSide collapsed={false} menus={menus} />
      </Drawer>
    </Header>
  );
}
