import React from 'react';
import { Breadcrumb } from 'antd';
import { getParents } from './utils';

const Breadcrump = ({ route }) => (
  <Breadcrumb>
    {getParents(route).map((crumb, index, list) => (
      <Breadcrumb.Item key={index}>
        {index < list.length - 1 ? (
          <span>{crumb.description || crumb.name}</span>
        ) : (
          <span style={{ fontWeight: 500 }}>
            {crumb.description || crumb.name}
          </span>
        )}
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default Breadcrump;
