import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import Loader from '../../loader/Loader';

export default function PDFViewer({ viewPdf }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  if (!viewPdf) {
    return <Loader />;
  }
  if (viewPdf === 'error') {
    return null;
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <Viewer fileUrl={viewPdf} plugins={[defaultLayoutPluginInstance]} />
    </Worker>
  );
}
