/* eslint-disable no-const-assign */
import React, { useEffect, useRef, useState } from 'react';
import { Spin, Tabs } from 'antd';
import axios from 'axios';
import { DiffOutlined, FileTextOutlined } from '@ant-design/icons';
import RequestList from './components/RequestList';
import { getNotification } from '../../../../service/notificationService';
import { ToolsContext, useToolsStore } from '../../../../context/Tools';
import { SORT_ORDER } from '../../../../constants/LazyTable';

const { TabPane } = Tabs;

export default function Notifications() {
  const cancelRef = useRef();
  const cancelRefCount = useRef();
  const [notifications, setNotifications] = useState();
  const [totalCount, setTotalCount] = useState();
  const [pageNUmber, setPageNUmber] = useState();
  const [isReload, setIsReload] = useState();
  const [Paramses, setParams] = useState([1, 2]);
  const [isLoading, setLoading] = useState(false);
  const { selectedUser, notificationCount, setNewNotificationCounts } =
    useToolsStore(ToolsContext);

  const getNotifications = () => {
    setLoading(true);
    setNotifications();
    const params = {
      search: {
        status: true,
        userId: selectedUser.userId,
        userRoleCode: selectedUser.role,
        form: Paramses,
      },
      page: pageNUmber,
      sortField: 'createdAt',
      sortOrder: SORT_ORDER.DESC,
    };
    getNotification(params, cancelRef)
      .then(result => {
        const list = result.content || [];
        setNotifications(list);
        setTotalCount(result.totalElements || 0);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setNotifications([]);
        }
      })
      .finally(() => setLoading(false));
  };

  const callback = key => {
    setTotalCount(0);
    if (key === '1') {
      setParams([1, 2]);
    } else if (key === '2') {
      setParams([3, 4, 5, 6, 7]);
    }
  };

  useEffect(() => {
    getNotifications();
    setNewNotificationCounts(cancelRefCount);
  }, [pageNUmber, isReload, Paramses]);

  return (
    <Tabs
      style={{ width: 450, maxHeight: 600, overflow: 'auto' }}
      defaultActiveKey="1"
      onChange={callback}
    >
      <TabPane
        tab={
          <span>
            <DiffOutlined />
            Хүсэлтүүд ({notificationCount?.requestQuantity})
          </span>
        }
        key="1"
      >
        {isLoading ? (
          <div
            style={{
              height: '222px',
              textAlign: 'center',
              paddingTop: '80px',
            }}
          >
            <Spin />
          </div>
        ) : (
          <RequestList
            totalCount={totalCount}
            notificationData={notifications}
            setPageNumber={setPageNUmber}
            callType="request"
            setReload={setIsReload}
          />
        )}
      </TabPane>
      <TabPane
        tab={
          <span>
            <FileTextOutlined />
            Оруулах мэдээлэл ({notificationCount?.reportQuantity})
          </span>
        }
        key="2"
      >
        {isLoading ? (
          <div
            style={{
              height: '222px',
              textAlign: 'center',
              paddingTop: '80px',
            }}
          >
            <Spin />
          </div>
        ) : (
          <RequestList
            totalCount={totalCount}
            notificationData={notifications}
            setPageNumber={setPageNUmber}
            callType="report"
            setReload={setIsReload}
          />
        )}
      </TabPane>
    </Tabs>
  );
}
