import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'antd';
import { CheckSquareFilled, ExclamationCircleFilled } from '@ant-design/icons';
import {
  ORG_SPECIAL_TYPE,
  ORG_STEP,
  ORG_TYPES_SCECIAL_FUNDS,
  ORG_WISH,
  TYPE,
} from '../../../../constants/Constant';
import OrgContext from '../../../../context/OrgContext';
import style from './style.module.scss';
import AutocompleteSelect from '../../../../components/Autocomplete';
import { getRefOrgTypes } from '../../../../service/referenceServices';

export default function Header() {
  const org = useContext(OrgContext);
  const [orgTypes, setOrgTypes] = useState();

  useEffect(() => {
    if (
      org.orgCallType === TYPE.DECIDE ||
      org.orgCallType === TYPE.EDITBYADMIN
    ) {
      const params = { search: { status: true } };
      getRefOrgTypes(params).then(result => {
        if (result) {
          setOrgTypes(result.content || []);
        }
      });
    }
  }, []);

  const onChangeOrgType = orgTypecode => {
    const selectedOrgType = orgTypes.find(z => z.code === orgTypecode);
    if (selectedOrgType) {
      org.setMain({
        refOrgTypeId: selectedOrgType.id,
        refOrgTypeCode: orgTypecode,
        refOrgTypeName: selectedOrgType.name,
        isGovSpecialFund: ORG_TYPES_SCECIAL_FUNDS.LocalSpecialFund,
        refFundFormId: ORG_SPECIAL_TYPE.SpecialPublicInstitutions,
      });
    }
  };

  const title = () => {
    if (
      org.orgCallType === TYPE.NEW ||
      org.orgCallType === TYPE.DRAFT ||
      org.mainState.wish === ORG_WISH.CREATE
    ) {
      return 'БАЙГУУЛЛАГА ШИНЭЭР БҮРТГҮҮЛЭХ МАЯГТ';
    }
    return 'БАЙГУУЛЛАГЫН МАЯГТ';
  };

  return (
    <>
      <h2>{title()}</h2>
      <h6>/ Баталгаажуулах шаардлагатай мэдээлэл /</h6>
      <div className="space-between">
        <div className="space-between">
          <span>Байгууллагын төрөл:</span>
          {org.orgCallType === TYPE.DECIDE ||
          org.orgCallType === TYPE.EDITBYADMIN ? (
            <div>
              <AutocompleteSelect
                key="orgTypeSelect"
                valuefield="code"
                value={org.mainState.refOrgTypeCode}
                data={orgTypes}
                onChange={onChangeOrgType}
                width="620px"
              />
            </div>
          ) : (
            <span className={style.refOrgType}>
              {org.mainState.refOrgTypeName}
            </span>
          )}
        </div>
      </div>
      {org.orgCallType === TYPE.DECIDE && (
        <Alert
          className={style.orgMessage}
          message="Буруу оруулсан талбаруудыг сонгож ТАТГАЛЗАХ товч дарснаар тухайн байгууллагын холбогдох и-мэйл хаягаар буцаах боломжтой."
          type="warning"
          icon={<CheckSquareFilled />}
          showIcon
        />
      )}
      {org.mainState.step === ORG_STEP.REJECTED &&
        org.mainState.wish === ORG_WISH.CREATE && (
          <Alert
            className={style.orgMessage}
            message="Байгууллага бүртгүүлэх хүсэлт БУЦААГДСАН байна. Улаанаар тодруулсан талбаруудыг засаад дахин илгээх боломжтой."
            description={`ТАЙЛБАР: ${org.mainState.rejectedDescription}`}
            type="warning"
            showIcon
            icon={<ExclamationCircleFilled />}
          />
        )}
    </>
  );
}
