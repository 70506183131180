import { React, useContext } from 'react';
import { Form, Input, Col, Row, Checkbox } from 'antd';
import OrgContext from '../../../../../../context/OrgContext';
import { TYPE } from '../../../../../../constants/Constant';
import PhoneNumber from '../../../../../../components/PhoneNumber';
import FormItemCyrillic from '../../../../../../components/FormItemCyrillic';
import style from '../style.module.scss';
import {
  PATTERN_EMAIL,
  PATTERN_PHONE,
  PATTERN_WORKPHONE,
} from '../../../../../../constants/Pattern';

export default function Contact(props) {
  const org = useContext(OrgContext);
  const { data, index, disabled, isRejected } = props;

  return (
    <div
      className={`${style.contactInfo} ${
        isRejected ? style.contactInfoError : ''
      }`}
    >
      <p className="sub-group" id={`contact-${index}`}>
        {org.orgCallType === TYPE.DECIDE && (
          <Checkbox onChange={e => org.onCheckFields(e, `contact-${index}`)} />
        )}
        {data.groupName}
      </p>
      <Row gutter={48}>
        <Col span={12}>
          <FormItemCyrillic name={`lastname${index}`} label="Овог">
            <Input
              disabled={disabled}
              value={data.lastname}
              defaultValue={data.lastname}
              onChange={event => {
                data.lastname = event.target.value;
              }}
            />
          </FormItemCyrillic>
          <FormItemCyrillic name={`firstname${index}`} label="Нэр">
            <Input
              disabled={disabled}
              value={data.firstname}
              defaultValue={data.firstname}
              onChange={event => {
                data.firstname = event.target.value;
              }}
            />
          </FormItemCyrillic>
          <FormItemCyrillic name={`position${index}`} label="Албан тушаал">
            <Input
              disabled={disabled}
              value={data.position}
              defaultValue={data.position}
              onChange={event => {
                data.position = event.target.value;
              }}
            />
          </FormItemCyrillic>
        </Col>
        <Col span={12}>
          <PhoneNumber
            name={`phone${index}`}
            label="Гар утас №"
            pattern={PATTERN_PHONE}
            size="small"
            childProps={{
              disabled,
              value: data.phone,
              defaultValue: data.phone,
              onChange: event => {
                data.phone = event.target.value;
              },
            }}
          />
          <PhoneNumber
            name={`workPhone${index}`}
            label="Ажлын утас №"
            pattern={PATTERN_WORKPHONE}
            size="small"
            childProps={{
              disabled,
              value: data.workPhone,
              defaultValue: data.workPhone,
              onChange: event => {
                data.workPhone = event.target.value;
              },
            }}
          />
          <Form.Item
            name={`email${index}`}
            label="Цахим шуудан:"
            labelAlign="left"
            rules={[
              !(disabled || index === 0) && {
                // type: 'email',
                pattern: PATTERN_EMAIL,
                message: 'И-мэйл буруу байна!',
              },
              {
                required: true,
                message: 'Энэ хэсгийг заавал бөглөнө үү!',
              },
            ]}
          >
            <Input
              disabled={
                org.orgCallType === TYPE.DECIDE
                  ? false
                  : disabled || index === 0
              }
              value={data.email}
              defaultValue={data.email}
              onChange={event => {
                data.email = event.target.value;
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
