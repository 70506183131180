import {
  deleteService,
  getRequest,
  patchService,
  postService,
  putService,
} from './Service';

const serviceBaseUrl = '/gap-notification-service';

const serviceLetterUrl = `${serviceBaseUrl}/notificationLetters`;
const serviceNewLetterCount = `${serviceLetterUrl}/countByToken`;
const serviceViewInboxLetters = `${serviceLetterUrl}/getByToken`;
const serviceViewReplyLetters = `${serviceLetterUrl}/getReplyByToken`;
const serviceSentLetterUrl = `${serviceBaseUrl}/notificationLetterPacks`;
const serviceViewMySentLetters = `${serviceSentLetterUrl}/getByToken`;
const serviceNotificationSupports = `${serviceBaseUrl}/notificationSupports`;
const serviceLetterReadManages = `${serviceBaseUrl}/notificationLetterReadManages`;
const serviceFindByPackId = `${serviceLetterUrl}/findByPackId`;

const serviceNotification = `${serviceBaseUrl}/notifications`;
const serviceGetServerDate = `${serviceNotification}/getTime`;
const serviceViewNotification = `${serviceNotification}/getByToken`;
const serviceViewNotificationCount = `${serviceNotification}/countByToken`;

const serviceFeedback = `${serviceBaseUrl}/notificationComplaints`;
const serviceFeedbackStatistics = `${serviceFeedback}/getQuantityGroupByYear`;
const serviceFeedbackUnsolve = `${serviceFeedback}/unsolve`;
const serviceFeedbackSolve = `${serviceFeedback}/solve`;

const serviceComment = `${serviceBaseUrl}/comments`;
const serviceCommentStatic = `${serviceComment}/getCommentStaticForAdmins`;

const serviceRating = `${serviceBaseUrl}/ratings`;
const serviceRatingStatic = `${serviceRating}/getRatingStaticForAdmins`;

const serviceGoogleAnalytic = `${serviceBaseUrl}/gaSessions`;
const servicePortalAccessCountDiagram = `${serviceGoogleAnalytic}/portalAccessCountDiagram`;

const serviceGoogleAnalyticPage = `${serviceBaseUrl}/gaSessionPages`;
const serviceTopAccessCountDiagram = `${serviceGoogleAnalyticPage}/topAccessCountDiagram`;
const serviceOrgAccessCountDiagram = `${serviceGoogleAnalyticPage}/orgAccessCountDiagram`;
const serviceTopFormAccessCountDiagram = `${serviceGoogleAnalyticPage}/topFormAccessCountDiagram`;

const serviceEmailLog = `${serviceBaseUrl}/eMailLogs`;

export const getLetters = async (params = null) =>
  await getRequest(serviceLetterUrl, params);

export const createLetter = async values =>
  await postService(serviceSentLetterUrl, values);

export const readLetter = async id =>
  await patchService(`${serviceLetterUrl}/check/${id}`);

export const readReplyLetter = async id =>
  await patchService(`${serviceLetterUrl}/checkReply/${id}`);

export const findByPackId = async param =>
  await getRequest(serviceFindByPackId, param);

export const solveLetter = async (id, values) =>
  await patchService(`${serviceLetterUrl}/solve/${id}`, values);

export const replyLetter = async (id, values) =>
  await patchService(`${serviceLetterUrl}/reply/${id}`, values);

export const getFeedbackStatistics = async param =>
  await getRequest(serviceFeedbackStatistics, param);

export const unsolveFeedback = async (id, values) =>
  await putService(`${serviceFeedbackUnsolve}/${id}`, values);

export const solveFeedback = async (id, values) =>
  await putService(`${serviceFeedbackSolve}/${id}`, values);

export const getNewLetterCount = async () =>
  await getRequest(serviceNewLetterCount);

export const getNotificationSupports = async (params = null) =>
  await getRequest(serviceNotificationSupports, params);

export const getNotification = async (params, cancelRef) =>
  await getRequest(serviceViewNotification, params, cancelRef);

export const getNotificationCount = async cancelRef =>
  await getRequest(serviceViewNotificationCount, null, cancelRef);

export const notificationRead = async id =>
  await patchService(`${serviceNotification}/${id}`);

export const deleteCommentById = async id =>
  await deleteService(`${serviceComment}/${id}`);

export const getRatingStaticForAdmins = async (param, cancelRef) =>
  await getRequest(serviceRatingStatic, param, cancelRef);

export const getCommentStaticForAdmins = async (param, cancelRef) =>
  await getRequest(serviceCommentStatic, param, cancelRef);

export const getPortalAccessCountDiagram = async (param, cancelRef) =>
  await getRequest(servicePortalAccessCountDiagram, param, cancelRef);

export const getTopAccessCountDiagram = async (param, cancelRef) =>
  await getRequest(serviceTopAccessCountDiagram, param, cancelRef);

export const getOrgAccessCountDiagram = async (param, cancelRef) =>
  await getRequest(serviceOrgAccessCountDiagram, param, cancelRef);

export const getTopFormAccessCountDiagram = async (param, cancelRef) =>
  await getRequest(serviceTopFormAccessCountDiagram, param, cancelRef);

export const getServerDate = async () => {
  let serverDate = new Date();
  try {
    serverDate = await getRequest(serviceGetServerDate);
  } catch {
    serverDate = new Date();
  }
  return new Date(serverDate);
};

export {
  serviceFeedback,
  serviceLetterReadManages,
  serviceViewMySentLetters,
  serviceViewInboxLetters,
  serviceViewReplyLetters,
  serviceNotificationSupports,
  serviceViewNotification,
  serviceViewNotificationCount,
  serviceComment,
  serviceEmailLog,
};
