import { message } from 'antd';
import axios from 'axios';
import Axios from './Axios';
import { GOOGLE_MAP_KEY } from '../constants/SystemConstants';
import { errorCatch } from '../tools/Tools';
import mockData from '../mock-data';
import { getParamsQueryString } from './utils';

const defaultConfig = (cancelRef, head = {}) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    ...head,
  };

  if (localStorage.getItem('orgId') && !headers.orgId) {
    headers.orgId = localStorage.getItem('orgId');
  }
  if (localStorage.getItem('userRole') && !headers.userRole) {
    headers.userRole = localStorage.getItem('userRole');
  }
  headers.orgId === undefined && delete headers.orgId;
  headers.userRole === undefined && delete headers.userRole;

  let cancelToken;
  if (cancelRef) {
    if (cancelRef.current) {
      cancelRef.current.cancel();
    }
    cancelRef.current = axios.CancelToken.source();
    cancelToken = cancelRef.current?.token;
  }
  return { headers, cancelToken };
};

export async function getRequest(serviceName, queryParams, cancelRef, head) {
  const params = getParamsQueryString(queryParams);
  let response = null;
  try {
    response = await Axios.get(
      serviceName + params,
      defaultConfig(cancelRef, head)
    );
    // if (cancelRef) cancelRef.current = null;
  } catch (error) {
    errorCatch(error, serviceName);
    const serviceKey = serviceName.split('?')[0];
    if (process.env.NODE_ENV === 'development' && mockData[serviceKey]) {
      return mockData[serviceKey];
    }
    throw error;
  }
  return response?.data;
}

export async function postService(serviceName, data, params, head) {
  const response = await Axios.post(serviceName, data, {
    ...defaultConfig(null, {
      orgId: head?.orgId,
      userRole: head?.userRole,
    }),
    params,
  }).catch(error => {
    errorCatch(error, serviceName);
    throw error;
  });
  if (response.status !== 200) {
    message.warning('АМЖИЛТГҮЙ:', response.statusText);
  }
  return response?.data;
}

export async function putService(serviceName, data = null) {
  const response = await Axios.put(serviceName, data, defaultConfig()).catch(
    error => {
      errorCatch(error, serviceName);
      throw error;
    }
  );
  if (response.status !== 200) {
    message.warning('АМЖИЛТГҮЙ:', response.statusText);
  }
  return response.data;
}

export async function deleteService(serviceName, data) {
  const config = {
    data,
    ...defaultConfig(),
  };

  if (serviceName.includes('/gap-user-service/userManages')) {
    config.headers.userRole = 'guest';
  }

  return await Axios.delete(serviceName, config).catch(error => {
    errorCatch(error, serviceName);
    throw error;
  });
}

export async function patchService(serviceName, data = null) {
  return await Axios.patch(serviceName, data, defaultConfig()).catch(error => {
    errorCatch(error, serviceName);
    throw error;
  });
}

export async function getGenerateUuid() {
  return await Axios.get(
    '/gap-file-service/files/generateUuid',
    defaultConfig()
  );
}

export async function getMapLocationByLatLng(latitude, longitude) {
  return await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=mn&region=MN&sensor=false&key=${GOOGLE_MAP_KEY}`
  ).then(response => response.json());
}

export async function getIPAddress() {
  let ipAddress = '';
  try {
    const res = await axios.get('https://geolocation-db.com/json/');
    ipAddress = res.data.IPv4;
  } catch (error) {
    ipAddress = 'warn';
  }
  return ipAddress;
}
