import React from 'react';
import { MailFilled, EnvironmentFilled, PhoneFilled } from '@ant-design/icons';
import { Button } from 'antd';
import style from '../style.module.scss';
import stateImg from '../../../images/suld.svg';
import newLoginImg from '../../../images/newLogin.svg';
import { PORTAL_URL } from '../../../constants/SystemConstants';

export default function RegisterInfo() {
  return (
    <div className={style.info}>
      <div className={style.logo}>
        <img src={stateImg} height={54} />
        <span className={style.logoName}>ШИЛЭН ДАНС</span>
      </div>
      <div>
        <img src={newLoginImg} width="90%" style={{ maxWidth: 500 }} />
      </div>
      <div style={{ fontSize: 12 }}>
        <div style={{ marginBottom: 20 }}>
          <EnvironmentFilled />
          <span>
            Монгол Улс, Улаанбаатар 15160, Монгол Улсын Сангийн Яам ,
            <br />
            С.Данзангийн Гудамж, Засгийн Газрын II Байр Д Корпус
          </span>
        </div>
        <PhoneFilled /> +(976)-7100 1111
        <br />
        <MailFilled /> shilendans@mof.gov.mn
      </div>
      <div className={style.btnSystemInstructions}>
        <Button type="primary" href={`${PORTAL_URL}/learning`} target="_blank">
          СИСТЕМИЙН ЗААВАР, ГАРЫН АВЛАГА
        </Button>
      </div>
    </div>
  );
}
