import React from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import Routes from './routes/Routes';

const keycloak = Keycloak('/keycloak.json');
const initOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false,
};

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
    <Routes />
  </ReactKeycloakProvider>,
  document.getElementById('root')
);
