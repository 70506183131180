import React from 'react';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import infoImg from '../images/info.svg';
import successImg from '../images/checked.svg';

export const Confirm = (
  handleOK,
  title = 'Та устгахдаа итгэлтэй байна уу?',
  content = null
) => {
  Modal.confirm({
    title,
    content,
    icon: <QuestionCircleOutlined />,
    okButtonProps: {},
    okText: 'Тийм',
    cancelText: 'Үгүй',
    cancelButtonProps: { type: 'secondary' },
    onOk() {
      handleOK && handleOK();
    },
  });
};

export const Success = (action, title = null, content = null) => {
  Modal.success({
    title: title || 'Амжилттай',
    content,
    afterClose: () => {
      action && action();
    },
  });
};

export const Warning = ({ title, content, handleOK }) => {
  Modal.warning({
    title,
    content,
    okText: 'Хаах',
    onOk() {
      handleOK && handleOK();
    },
  });
};

export const Info = content => {
  Modal.info({
    centered: true,
    className: 'info-modal',
    closable: true,
    icon: false,
    width: content.width || 300,
    content: (
      <div>
        {content.isSuccess ? (
          <img src={successImg} height="70" />
        ) : (
          <img src={infoImg} height="120" />
        )}
        <p>{content.header}</p>
        <span>{content.text}</span>
        {content.data && content.data}
      </div>
    ),
    okText: content.okText || 'Хаах',
    onOk() {
      content.handleOk && content.handleOk();
    },
    afterClose() {
      content.afterClose && content.afterClose();
    },
  });
};

export const CountdownModal = content => {
  let secondsToGo = 10;

  const modal = Modal.info({
    centered: true,
    className: 'info-modal',
    icon: false,
    width: content.width || 300,
    content: (
      <div>
        {content.isSuccess ? (
          <img src={successImg} height="70" />
        ) : (
          <img src={infoImg} height="120" />
        )}
        <p style={{ fontSize: 18 }}>{content.header}</p>
        <p style={{ fontSize: 15, color: 'red' }}>{content.text}</p>
        {content.data && content.data}
      </div>
    ),
    okText: `Хаах (${secondsToGo})`,
    okButtonProps: { disabled: true },
    onOk() {
      content.handleOk && content.handleOk();
    },
    afterClose() {
      content.afterClose && content.afterClose();
    },
  });

  const timer = setInterval(() => {
    secondsToGo -= 1;
    if (secondsToGo === 0) {
      modal.update({
        okText: `Хаах`,
        okButtonProps: { disabled: false },
      });
    } else {
      modal.update({
        okText: `Хаах (${secondsToGo})`,
      });
    }
  }, 1000);

  setTimeout(() => {
    clearInterval(timer);
  }, secondsToGo * 1000);
};
