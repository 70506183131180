import React, { useEffect, useContext, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { useKeycloak } from '@react-keycloak/web';
import { isNil } from 'lodash';
import PageHeader from './header/PageHeader';
import { ToolsContext } from '../context/Tools';
import ReportContextProvider from '../context/ReportContext';
import Loader from '../loader/Loader';

import { USER_STEP } from '../constants/Constant';

import { buildPaths, generateRoutes } from './utils';
import Pages from './Pages';
import { getMenusByToken } from '../service/coreServices';
import MenuSide from './menu/MenuSide';

const { Content } = Layout;

function Admin() {
  const { keycloak } = useKeycloak();
  const { selectedUser } = useContext(ToolsContext);

  const [collapsed, setCollapsed] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [menus, setMenus] = useState();

  useEffect(() => {
    if (!keycloak?.authenticated) {
      keycloak?.login();
    }
  }, [keycloak]);

  useEffect(() => {
    if (!selectedUser) {
      setMenus(null);
      return;
    }
    getMenusByToken()
      .then(result => {
        const listMenu = result || [];
        setMenus(buildPaths(listMenu));
        const menulist = generateRoutes(listMenu);
        setRoutes(menulist);
      })
      .catch(() => {
        setMenus([]);
      });
  }, [selectedUser]);

  if (!selectedUser || selectedUser.step !== USER_STEP.APPROVED) {
    if (window.location.pathname !== '/checkRegister') {
      localStorage.setItem('redirect-path', window.location.pathname);
    }
    return <Redirect to="/checkRegister" />;
  }

  if (isNil(menus)) {
    return <Loader />;
  }

  return (
    <Layout style={{ height: '100%' }}>
      <div className="admin-full-sider">
        <MenuSide collapsed={collapsed} menus={menus} />
      </div>
      <Layout className="tabs-content" theme="white">
        <PageHeader
          menus={menus}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Content className="main-content">
          <ReportContextProvider>
            <Switch>
              {routes.map(route => (
                <Route key={route.code} path={route.path}>
                  <Pages route={route} />
                </Route>
              ))}
            </Switch>
          </ReportContextProvider>
        </Content>
      </Layout>
    </Layout>
  );
}
export default Admin;
