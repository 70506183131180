import { getRequest, patchService, postService, putService } from './Service';

const serviceBaseUrl = '/gap-core-service';

const serviceMenu = `${serviceBaseUrl}/menus`;
const serviceGetMenuByToken = `${serviceMenu}/getByToken`;
const serviceMenuShows = `${serviceBaseUrl}/menuShows`;

export const getMenus = async lazyParams =>
  await getRequest(serviceMenu, lazyParams);

export const createMenu = async values =>
  await postService(serviceMenu, values);

export const updateMenu = async (id, values) =>
  await putService(`${serviceMenu}/${id}`, values);

export const changeMenuPriority = async (id, isUp) => {
  const serviceName = isUp ? 'priorityUp' : 'priorityDown';
  await patchService(`${serviceMenu}/${serviceName}/${id}`);
};

export const getMenuShows = async lazyParams =>
  await getRequest(serviceMenuShows, lazyParams);

export const createMenuShows = async values =>
  await postService(serviceMenuShows, values);

export const getMenusByToken = async lazyParams =>
  await getRequest(serviceGetMenuByToken, lazyParams);
