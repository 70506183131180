import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, Col, Button, AutoComplete, Form, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AlertDialogs from '../../organizatioinRegister/alert/Alert';
import { MSG, TYPE, USERROLE } from '../../../constants/Constant';
import { ToolsContext } from '../../../context/Tools';
import { focusElement } from '../../../tools/Tools';
import RequestAddUser from '../RequestAddUser';
import style from '../style.module.scss';
import { Info } from '../../../components/Confirm';
import { PATTERN_NUMBER } from '../../../constants/Pattern';
import FormItemCyrillic from '../../../components/FormItemCyrillic';
import { FILTER_MODE } from '../../../constants/LazyTable';
import OrgContext from '../../../context/OrgContext';
import {
  getActiveOrganizationsByFields,
  getOrgs,
} from '../../../service/organizationServices';

const { Option } = AutoComplete;

export default function CheckOrg(props) {
  const { selectedRole, callType, userInfo } = props;

  const toolsStore = useContext(ToolsContext);
  const orgContext = useContext(OrgContext);

  const cancelRef = useRef();

  const [form] = Form.useForm();
  const [orgOptions, setOrgOptions] = useState([]);
  const [orgRegisterNo, setOrgRegisterNo] = useState();
  const [orgTaxNo, setOrgTaxNo] = useState();
  const [orgName, setOrgName] = useState();
  const [selectedOrg, setSelectedOrg] = useState({});
  const [visibleAlert, setVisibleAlert] = React.useState();
  const [disabledField, setDisabledField] = useState(null);

  let loadLazyTimeout = null;

  const onChange = (searchText, type) => {
    setDisabledField(searchText ? type : '');
    if (type === 'registerNo') {
      form.setFieldsValue({ taxNo: 0, name: null });
      setOrgTaxNo();
      setOrgName();
    } else if (type === 'taxNo') {
      form.setFieldsValue({ registerNo: 0, name: null });
      setOrgRegisterNo();
      setOrgName();
    } else if (type === 'name') {
      form.setFieldsValue({ registerNo: 0, taxNo: 0 });
      setOrgRegisterNo();
      setOrgTaxNo();
    }
    form.validateFields().then(() => {
      if (loadLazyTimeout) {
        clearTimeout(loadLazyTimeout);
      }
      loadLazyTimeout = setTimeout(() => {
        if (type === 'registerNo') setOrgRegisterNo(searchText);
        else if (type === 'taxNo') setOrgTaxNo(searchText);
        else if (type === 'name') setOrgName(searchText);
        setSelectedOrg({});
      }, 500);
    });
  };

  const checkOrg = () => {
    if (!orgRegisterNo && !orgTaxNo && !orgName) {
      message.warn('Байгууллагын мэдээлэл оруулна уу!');
      focusElement('orgRegisterNo');
      return;
    }
    toolsStore.setIsShowLoader(true);

    const search = { status: true, isActive: true, isLog: false };
    if (orgRegisterNo) search.registerNo = orgRegisterNo;
    else if (orgTaxNo) search.taxNo = orgTaxNo;
    else if (orgName) search.name = orgName;

    getOrgs({ search })
      .then(result => {
        if (!result) return;
        const orgs = (result.content || []).filter(or => !or.originId);
        let selectOrg = orgs.findLast(or => or.step === 2);
        if (!selectOrg) selectOrg = orgs.findLast(or => or.step === 3);

        if (orgs.length === 0 || selectOrg?.step === 3) {
          if (selectedRole?.code === USERROLE.orgAdmin) {
            Info({
              header: 'Байгууллага бүртгэлгүй байна.',
              text: 'Байгууллага бүртгүүлэх хүсэлт илгээнэ үү.',
              okText: 'Бүртгүүлэх',
              handleOk: () => {
                orgContext.setMain({
                  registerNo: orgRegisterNo,
                  taxNo: orgTaxNo,
                  name: orgName,
                });
                setVisibleAlert(true);
              },
            });
          } else {
            Info({
              header: 'Байгууллага бүртгэлгүй байна.',
              text: 'Хэрэглэгч сонгох хэсгээс Байгууллагын админыг сонгож байгууллагаа бүртгүүлээд дахин хүсэлт илгээнэ үү!',
            });
          }
        } else if (
          selectOrg &&
          !selectOrg?.isWish &&
          // selectOrg?.isLog &&
          selectOrg?.isActive &&
          selectOrg?.status &&
          selectOrg?.step === 2
        ) {
          Info({
            header: 'Байгууллага бүртгэлтэй байна.',
            text: 'Мэдээллээ бөглөөд хүсэлт илгээнэ үү.',
            afterClose: () => {
              setSelectedOrg(selectOrg);
              form.setFieldsValue(selectOrg);
            },
          });
        } else {
          Info({
            header: 'Тухайн байгууллагаар хүсэлт илгээсэн байна.',
            text: 'БАТАЛГААЖААГҮЙ, хянагдах шатандаа явж байна. Баталгаажсаны дараа хэрэглэгч болох хүсэлтээ илгээх боломжтой.',
          });
        }
      })
      .finally(() => toolsStore.setIsShowLoader(false));
  };

  const getOrgData = () => {
    if (!orgRegisterNo && !orgTaxNo && !orgName) {
      return;
    }

    const search = {};
    if (orgRegisterNo) {
      search.registerNo = {
        value: orgRegisterNo,
        matchMode: FILTER_MODE.CONTAINS,
      };
    } else if (orgTaxNo) {
      search.taxNo = {
        value: orgTaxNo,
        matchMode: FILTER_MODE.CONTAINS,
      };
    } else if (orgName) {
      search.name = {
        value: orgName,
        matchMode: FILTER_MODE.CONTAINS,
      };
    }
    const params = { search, requestedFields: 'id,name,registerNo,taxNo' };
    setOrgOptions(null);
    getActiveOrganizationsByFields(params, cancelRef).then(result => {
      if (result) {
        setOrgOptions(result.content || []);
      }
    });
  };

  useEffect(() => {
    if (callType === TYPE.EDIT) {
      const org = {
        id: userInfo.orgId,
        name: userInfo.orgName,
        registerNo: userInfo.orgRegisterNo,
        taxNo: userInfo.orgTaxNo,
      };
      form.setFieldsValue(org);
      setSelectedOrg(org);
    }
  }, []);

  useEffect(() => {
    getOrgData();
  }, [orgRegisterNo, orgTaxNo, orgName]);

  return (
    <>
      <p className={style.title}>Байгууллагын мэдээлэл</p>
      <Form name="checkOrg" layout="vertical" form={form}>
        <Row gutter={80} align="middle">
          <Col lg={12}>
            <Form.Item
              label="Регистрийн дугаар"
              name="registerNo"
              rules={[
                {
                  pattern: PATTERN_NUMBER,
                  message: 'Зөвхөн тоо оруулна уу',
                },
              ]}
            >
              <AutoComplete
                disabled={disabledField && disabledField !== 'registerNo'}
                id="orgRegisterNo"
                maxLength="7"
                notFoundContent={
                  !orgOptions ? <Spin indicator={<LoadingOutlined />} /> : null
                }
                onChange={e => onChange(e, 'registerNo')}
              >
                {orgOptions &&
                  orgOptions.map((data, index) => (
                    <Option key={index} value={data.registerNo}>
                      {data.registerNo}
                    </Option>
                  ))}
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col lg={12}>
            <FormItemCyrillic
              label="Байгууллагын нэр"
              name="name"
              required={false}
            >
              <AutoComplete
                disabled={disabledField && disabledField !== 'name'}
                placeholder={MSG.CYRILLIC}
                notFoundContent={
                  !orgOptions ? <Spin indicator={<LoadingOutlined />} /> : null
                }
                onChange={e => onChange(e, 'name')}
              >
                {orgOptions &&
                  orgOptions.map((data, index) => (
                    <Option key={index} value={data.name}>
                      {data.name}
                    </Option>
                  ))}
              </AutoComplete>
            </FormItemCyrillic>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Татвар төлөгчийн дугаар"
              name="taxNo"
              rules={[
                {
                  pattern: PATTERN_NUMBER,
                  message: 'Зөвхөн тоо оруулна уу',
                },
              ]}
            >
              <AutoComplete
                disabled={disabledField && disabledField !== 'taxNo'}
                maxLength="10"
                notFoundContent={
                  !orgOptions ? <Spin indicator={<LoadingOutlined />} /> : null
                }
                onChange={e => onChange(e, 'taxNo')}
              >
                {orgOptions &&
                  orgOptions.map((data, index) => (
                    <Option key={index} value={data.taxNo}>
                      {data.taxNo}
                    </Option>
                  ))}
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Button type="primary" size="large" onClick={checkOrg}>
              Шалгах
            </Button>
          </Col>
        </Row>
      </Form>
      {selectedOrg.id && (
        <>
          <p className={style.title}>Хэрэглэгчийн мэдээлэл</p>
          <RequestAddUser
            callType={callType}
            userInfo={userInfo}
            selectedRole={selectedRole}
            selectedOrg={selectedOrg}
            close={isSuccess => props.close(isSuccess)}
          />
        </>
      )}
      {visibleAlert && (
        <AlertDialogs
          visible={visibleAlert}
          close={isSuccess => {
            setVisibleAlert(false);
            if (isSuccess === true) {
              props.close(isSuccess);
            }
          }}
        />
      )}
    </>
  );
}
