import React from 'react';

export const RegisterNo = React.lazy(() => import('./components/RegisterNo'));

export const CertificateNo = React.lazy(() =>
  import('./components/CertificateNo')
);

export const TaxNo = React.lazy(() => import('./components/TaxNo'));

export const OrgName = React.lazy(() => import('./components/OrgName'));

export const RegisteredAt = React.lazy(() =>
  import('./components/RegisteredAt')
);

export const TaxAt = React.lazy(() => import('./components/TaxAt'));

export const ActivityCode = React.lazy(() =>
  import('./components/ActivityCode')
);

export const ActivityContent = React.lazy(() =>
  import('./components/ActivityContent')
);

export const WhetherHasBranch = React.lazy(() =>
  import('./components/WhetherHasBranch')
);
export const ParentOrganization = React.lazy(() =>
  import('./components/ParentOrganization')
);

export const ChooseTez = React.lazy(() => import('./components/ChooseTez'));

export const SpecialFundSelectType = React.lazy(() =>
  import('./components/SpecialFundSelectType')
);

export const SpcecialFundName = React.lazy(() =>
  import('./components/SpecialFundName')
);

export const SpecialFundTypeRadio = React.lazy(() =>
  import('./components/SpecialFundTypeRadio')
);

export const InfoTypes = React.lazy(() => import('./components/InfoTypes'));

export const OrgLocation = React.lazy(() => import('./components/OrgLocation'));

export const LocalActivity = React.lazy(() =>
  import('./components/LocalActivity')
);

export const EquityPartCompany = React.lazy(() =>
  import('./components/EquityPartCompany')
);

export const FirmNGOType = React.lazy(() => import('./components/FirmNGOType'));
