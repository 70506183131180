import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Space, Button, Modal } from 'antd';
import {
  CalendarOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  InfoCircleFilled,
} from '@ant-design/icons';
import moment from 'moment';
import { ToolsContext } from '../../../../context/Tools';
import style from './style.module.scss';
import Item from './Item';
import {
  DATEFORMAT,
  MSG,
  TYPE,
  USERROLE,
  USER_STEP,
} from '../../../../constants/Constant';
import OrgModal from '../../../orgRequest/components/OrgModal';
import Register from '../Register';
import { deleteUserManage } from '../../../../service/userService';
import { Confirm, Info, Success } from '../../../../components/Confirm';

function OrgInfo(props) {
  const history = useHistory();

  const toolsStore = useContext(ToolsContext);

  const { orgUser } = props;

  const [isShowDetail, setIsShowDetail] = useState();
  const [orgData, setOrgData] = useState();
  const [userRequest, setUserRequest] = useState({
    isVisible: false,
    userInfo: null,
  });

  const chooseOrg = () => {
    localStorage.setItem('userRole', orgUser.userRoleCode);
    if (orgUser.orgId) {
      localStorage.setItem('orgId', orgUser.orgId);
    }
    toolsStore.setterSelectedUser(orgUser);
    toolsStore.setOrg({});
    if (window.location.pathname !== '/checkRegister') {
      history.push('/OrgInfo/OrgProfile');
    }
  };

  const viewOrg = async () => {
    const org = { id: orgUser.orgId };
    setOrgData(org);
    setIsShowDetail(true);
  };

  const viewUserRequest = () => {
    setUserRequest({ isVisible: true, userInfo: orgUser });
  };

  const view = () => {
    if (orgUser?.administrators?.length > 0) {
      Info({
        isSuccess: true,
        text: 'Системд нэвтрэх эрхийг доорх эрх бүхий танай байгууллагын админ баталгаажуулах болно.',
        width: 400,
        data: orgUser?.administrators.length > 0 && (
          <div>
            {orgUser?.administrators?.map(el => (
              <p style={{ fontSize: 15 }}>
                {el?.lastname?.substring(0, 1)}.{el?.firstname} ({el?.phone})
              </p>
            ))}
          </div>
        ),
      });
    } else if (orgUser.userRoleCode === USERROLE.orgAdmin) {
      viewOrg();
    } else {
      viewUserRequest();
    }
  };

  const closeOrg = isSuccess => {
    setIsShowDetail(false);
    if (isSuccess && props.refresh) {
      props.refresh(true);
    }
  };

  const closeUserModal = isSuccess => {
    setUserRequest({ isVisible: false });
    if (isSuccess && props.refresh) {
      props.refresh(true);
    }
  };

  const deleteRequest = async () => {
    if (!orgUser.id) return;
    toolsStore.setIsShowLoader(true);

    try {
      await deleteUserManage(orgUser.id);
      Success(null, MSG.SUCCESS_DEL);
      if (props.refresh) {
        props.refresh(true);
      }
    } catch (error) {
      toolsStore.setIsShowLoader(false);
    }
  };

  const getType = () => {
    if (orgUser.step === USER_STEP.TEMP) {
      return TYPE.DRAFT;
    }
    if (orgUser.step === USER_STEP.REJECTED) {
      return TYPE.REJECTED;
    }
    return TYPE.VIEW;
  };

  return (
    <Row className={style.card}>
      {([USER_STEP.REJECTED, USER_STEP.TEMP].includes(orgUser?.step) ||
        (USER_STEP.ACTIVE === orgUser?.step &&
          (orgUser?.administrators?.length > 0 ||
            orgUser?.userRoleCode === USERROLE.audit))) && (
        <div className={style.delete}>
          <DeleteFilled
            className="deleteIcon"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              Confirm(
                deleteRequest,
                'Та илгээсэн хүсэлтээ устгахдаа итгэлтэй байна уу?'
              );
            }}
          />
        </div>
      )}
      <Col md={7}>
        <div className={style.userInfo}>
          <Item label="Хэрэглэгчийн эрх" value={orgUser.userRoleName} />
          <h6>
            <CalendarOutlined />
            {moment(orgUser.createdAt).format(DATEFORMAT)}
          </h6>
          {(orgUser.step === USER_STEP.ACTIVE ||
            orgUser.step === USER_STEP.EXPIRED) && (
            <Space className={style.statusChecking}>
              <InfoCircleFilled />
              <span>Хянагдаж байна</span>
            </Space>
          )}
          {orgUser.step === USER_STEP.APPROVED && (
            <Space className={style.statusSuccess}>
              <CheckCircleFilled />
              <span>Баталгаажсан</span>
            </Space>
          )}
          {orgUser.step === USER_STEP.REJECTED && (
            <Space className={style.statusCanceled}>
              <CloseCircleFilled />
              <span>Татгалзсан</span>
            </Space>
          )}
          {orgUser.step === USER_STEP.TEMP && (
            <Space className={style.statusTemp}>
              <InfoCircleFilled />
              <span>Түр хадгалсан</span>
            </Space>
          )}
        </div>
      </Col>
      <Col md={14} className={style.content}>
        <Row style={{ width: '100%' }}>
          {orgUser.userRoleCode.includes('org') ||
          orgUser.userRoleCode.includes('sub') ? (
            <>
              <Col span={12}>
                <Item
                  label="Байгууллагын нэр"
                  value={orgUser.organization.name || '-'}
                />
              </Col>
              <Col span={12}>
                <Item
                  label="Регистрийн дугаар"
                  value={orgUser.organization.registerNo || '-'}
                />
              </Col>
              <Col span={12}>
                <Item
                  label="Татварын дугаар"
                  value={orgUser.organization.taxNo || '-'}
                />
              </Col>
              <Col span={12}>
                <Item
                  label="Улсын бүртгэлийн дугаар"
                  value={orgUser.organization.certificateNo || '-'}
                />
              </Col>
            </>
          ) : (
            <>
              <Col span={12}>
                <Item label="Албан тушаал" value={orgUser.position || '-'} />
              </Col>
              <Col span={12}>
                <Item label="Утасны дугаар" value={orgUser.phone || '-'} />
              </Col>
              <Col span={16}>
                <Item label="И-мэйл хаяг" value={orgUser.email || '-'} />
              </Col>
            </>
          )}
          {orgUser.step === USER_STEP.REJECTED && (
            <>
              <Col span={12}>
                <Item
                  label="Татгалзсан огноо"
                  value={moment(orgUser.updatedAt).format(DATEFORMAT) || '-'}
                  type={TYPE.REJECTED}
                />
              </Col>
              <Col span={12}>
                <Item
                  label="Татгалзсан тайлбар"
                  value={orgUser.rejectedDescription || '-'}
                  type={TYPE.REJECTED}
                />
              </Col>
            </>
          )}
        </Row>
      </Col>
      <Col md={3} style={{ alignSelf: 'center' }}>
        {orgUser.step === USER_STEP.APPROVED ? (
          <Button type="primary" onClick={chooseOrg}>
            Сонгох
          </Button>
        ) : (
          <Button type="primary" onClick={view}>
            {orgUser?.administrators?.length > 0 ? 'Шалгах' : 'Харах'}
          </Button>
        )}
      </Col>
      {isShowDetail && (
        <OrgModal
          isModalVisibleOrg={isShowDetail}
          orgCallType={getType()}
          orgData={orgData}
          close={closeOrg}
        />
      )}
      {userRequest.isVisible && (
        <Modal
          title="Шинэ хэрэглэгч болох хүсэлт"
          visible={userRequest.isVisible}
          footer={false}
          onCancel={closeUserModal}
          width={700}
        >
          <Register
            callType={TYPE.EDIT}
            userManageId={userRequest.userInfo.id}
            close={closeUserModal}
          />
        </Modal>
      )}
    </Row>
  );
}

export default OrgInfo;
