import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import { TYPE } from '../../../../constants/Constant';
import OrgContext from '../../../../context/OrgContext';
import FileArray from '../../../../components/fileUpload/FileArray';
import { isDisabled, isRejectedField } from '../../CommonMethods';

export default function SectionFile() {
  const org = useContext(OrgContext);

  return (
    <>
      <p className="subtitle">ХОЁР. ФАЙЛ ХАВСАРГАХ</p>
      <div
        style={
          isRejectedField('file', org)
            ? { border: '2px solid red', padding: 10 }
            : undefined
        }
      >
        {org.orgCallType === TYPE.DECIDE && (
          <Checkbox
            style={{ marginBottom: 4 }}
            onChange={e => org.onCheckFields(e, `file`)}
          />
        )}
        <FileArray
          fileArrayState={org.fileState}
          setFileArrayState={org.setFileState}
          generateUuid={org.mainState.uuid}
          setGenerateUuid={uuid => org.setMain({ uuid })}
          isView={
            org.orgCallType === TYPE.EDITBYADMIN || isDisabled('file', org)
          }
        />
      </div>
    </>
  );
}
