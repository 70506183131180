import { WarningTwoTone } from '@ant-design/icons';
import { Space } from 'antd';

export const popOverContent = content => (
  <Space
    align="start"
    style={{
      maxWidth: '300px',
    }}
  >
    <WarningTwoTone twoToneColor="#ffcc00" />
    {content}
  </Space>
);
