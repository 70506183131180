import { message, Tag, Typography } from 'antd';
import moment from 'moment';
import { isObject, max } from 'lodash';
import axios from 'axios';
import {
  FFEDBACKTYPE,
  WHOM,
  FEEDBACKSTEPS,
  MSG,
  TEZTYPE,
  SPECIAL_FUND_TYPE,
  TIMERECURRINGID,
  USERROLE,
  SPECIAL_FUND_TYPE_LIST,
  TEZTYPE_LIST,
} from '../constants/Constant';
import { Warning } from '../components/Confirm';

export const convertLazyParamsToObj = lazyParams => {
  const obj = {
    search: lazyParams.filters,
    sortField: lazyParams.sortField,
    sortOrder: lazyParams.sortOrder,
    page: lazyParams.page,
    size: lazyParams.rows,
  };
  return obj;
};

export const logout = keycloak => {
  keycloak?.logout({ redirectUri: window.location.origin });
  localStorage.clear();
};

const checkUnauthorized = errorMethod => {
  if (errorMethod.statusText === 'Unauthorized' && errorMethod.status === 401) {
    const elementLogout = document.getElementById('hiddenLogoutBtn');
    if (elementLogout) {
      elementLogout.click();
    }
    // alert('Токены хугацаа дууссан тул системээс гарч байна...');
    // window.location.href = '/logout';
    return true;
  }
  return false;
};

export const errorCatch = (error, serviceName) => {
  if (axios.isCancel(error)) {
    return;
  }
  if (!error) {
    message.error('Серверийн алдаа гарлаа! Түр хүлээгээд дахин оролдоно уу.');
    return;
  }
  if (error.request) {
    if (checkUnauthorized(error.request)) {
      return;
    }
    if (
      error.request.statusText === 'Forbidden' &&
      error.request.status === 403
    ) {
      Warning({ title: MSG.NOT_AUTHORIZED, content: serviceName || '' });
      return;
    }
    if (typeof error.response?.data === 'string') {
      Warning({ title: error.response.data || error.response.statusText });
      return;
    }
    message.warning('Серверийн алдаа гарлаа! Түр хүлээгээд дахин оролдоно уу.');
  } else if (error.response) {
    if (checkUnauthorized(error.response)) {
      return;
    }
    if (typeof error.response.data === 'string') {
      message.warning(error.response.data);
    }
  } else {
    message.warning(error.message);
  }
};

export const requireFieldFocus = () => {
  message.warning('Мэдээллээ бүрэн зөв бөглөсөн эсэхээ шалгана уу!');
  const el = document.querySelector(`.ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-select-selection-search-input,
    .ant-form-item-has-error .ant-input-number-input, 
    .ant-form-item-has-error .ant-picker-input input,
    .ant-form-item-has-error .ant-radio input`);
  if (el) el.focus();
};

export const convertFeedbackType = type => {
  switch (type) {
    case FFEDBACKTYPE.COMPLAINT:
      return <Tag color="green">Гомдол</Tag>;
    case FFEDBACKTYPE.FEEDBACK:
      return <Tag color="yellow">Санал хүсэлт</Tag>;
    default:
      return type;
  }
};
export const convertExtendTimeRecurring = id => {
  switch (id) {
    case TIMERECURRINGID.everyMonth:
      return <Typography.Text>Сар бүр</Typography.Text>;
    case TIMERECURRINGID.eachSeason:
      return <Typography.Text>Улирал бүр</Typography.Text>;
    case TIMERECURRINGID.aboutEach:
      return <Typography.Text>Тухай бүр</Typography.Text>;
    case TIMERECURRINGID.everyYear:
      return <Typography.Text>Жил бүр</Typography.Text>;
    case TIMERECURRINGID.everyHhalfYear:
      return <Typography.Text>Хагас жил бүр</Typography.Text>;
    default:
      return id;
  }
};

export const convertTezType = type => {
  const findTezType = TEZTYPE_LIST.find(tez => tez.id === type);
  switch (type) {
    case TEZTYPE.typeCountry:
      return <Tag color="green">{findTezType?.name}</Tag>;
    case TEZTYPE.typeLocal:
      return <Tag color="yellow">{findTezType?.name}</Tag>;
    default:
      return type;
  }
};

export const specialFundType = type => {
  const findType = SPECIAL_FUND_TYPE_LIST.find(
    fundType => fundType.id === type
  );
  const typeStr = findType ? findType.name : type;
  let color = 'red';
  switch (type) {
    case SPECIAL_FUND_TYPE.ACCUMULATION:
      color = 'green';
      break;
    case SPECIAL_FUND_TYPE.SOCIAL_SECURITY:
      color = 'yellow';
      break;
    case SPECIAL_FUND_TYPE.ANOTHER_SPECIAL_FUND_TYPE:
      color = 'blue';
      break;
    default:
      color = 'red';
  }
  return <Tag color={color}>{typeStr}</Tag>;
};

export const convertFeedbacWhom = type => {
  switch (type) {
    case WHOM.whomOrg:
      return <Tag color="green">Байгууллага</Tag>;
    case WHOM.whomAudit:
      return <Tag color="yellow">Аудит Байгууллага</Tag>;
    case WHOM.whomAdmin:
      return <Tag color="yellow">Нэгдсэн Админ</Tag>;
    default:
      return type;
  }
};

export const convertFeedbacSteps = step => {
  switch (step) {
    case FEEDBACKSTEPS.ACTIVE:
      return <Tag color="green">Идэвхтэй</Tag>;
    case FEEDBACKSTEPS.SOLVED:
      return <Tag color="yellow">Шийдсэн</Tag>;
    case FEEDBACKSTEPS.UNSOLVED:
      return <Tag color="yellow">Буцаасан</Tag>;
    default:
      return step;
  }
};

export const convertCurrencyFormat = value =>
  Number(value || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const focusElement = id => {
  const element = document.getElementById(id);
  if (element) element.focus();
};

export const isEmptyObject = obj => !obj || Object.keys(obj).length === 0;

export const sortArray = (list, sortField = 'priority') => {
  if (!list) return [];
  return list.sort((a, b) => (a[sortField] > b[sortField] ? 1 : -1));
};

/** unuudruus hoishih ognoog songohgui */
export const disabledDate = current =>
  current && current > moment().endOf('day');

export const isChangedData = (newData, oldData) => {
  let isChanged = false;
  Object.entries(newData).forEach(([key, newValue]) => {
    if (isObject(newValue)) {
      isChanged = isChangedData(newValue, oldData[key] || {});
    } else if (newValue !== oldData[key]) {
      isChanged = true;
    }
  });
  return isChanged;
};

export const getDifferenceInDays = (date1, date2) => {
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  return diffInMs / (1000 * 60 * 60 * 24);
};

export const getNextNo = (list, field) =>
  (max((list || []).map(row => row[field])) || 0) + 1;

export const detectBrowser = () => {
  let browser = '';
  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    browser = 'Google Chrome';
  }
  // FIREFOX
  else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    browser = 'Mozilla Firefox';
  }
  // INTERNET EXPLORER
  else if (navigator.userAgent.indexOf('MSIE') !== -1) {
    browser = 'Internet Exploder';
  }
  // EDGE
  else if (navigator.userAgent.indexOf('Edge') !== -1) {
    browser = 'Internet Exploder';
  }
  // SAFARI
  else if (navigator.userAgent.indexOf('Safari') !== -1) {
    browser = 'Safari';
  }
  // OPERA
  else if (navigator.userAgent.indexOf('Opera') !== -1) {
    browser = 'Opera';
  }
  // YANDEX BROWSER
  else if (navigator.userAgent.indexOf('YaBrowser') !== -1) {
    browser = 'Yandex Browser';
  }
  // OTHERS
  else {
    browser = 'Others';
  }
  return browser;
};

export const getRoleColor = role => {
  if (role === USERROLE.admin) {
    return 'red';
  }
  if (role === USERROLE.audit) {
    return 'lime';
  }
  if (role === USERROLE.orgAdmin) {
    return 'purple';
  }
  if (role === USERROLE.orgUser) {
    return 'green';
  }
  if (role === USERROLE.orgAudit) {
    return 'gold';
  }
  return 'blue';
};
