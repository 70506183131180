import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form } from 'antd';
import { Info } from '../../../components/Confirm';
import style from '../style.module.scss';
import AutocompleteSelect from '../../../components/Autocomplete';
import CheckOrg from './CheckOrg';
import RequestAddUser from '../RequestAddUser';
import { ToolsContext } from '../../../context/Tools';
import { TYPE } from '../../../constants/Constant';
import { getUserById, getUserRoles } from '../../../service/userService';
import { OrgStore } from '../../../context/OrgContext';

export default function Register(props) {
  const toolsStore = useContext(ToolsContext);
  const { callType, userManageId } = props;
  const [userRoles, setUserRoles] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [userInfo, setUserInfo] = useState();

  const changeRole = roleId => {
    setSelectedRole((userRoles || []).find(role => role.id === roleId));
  };

  const getData = async () => {
    if (!toolsStore.generalInfo.orgList?.length) {
      Info({
        header: 'Шилэн дансны системд бүртгэлгүй хэрэглэгч байна.',
        text: 'Бүртгүүлэх бол хэрэглэгчийн эрхээ сонгоод хүсэлт илгээнэ үү!',
      });
    }
    try {
      const params = { search: { status: true } };
      const { content } = await getUserRoles(params);

      const roles = (content || []).filter(
        role => role.code !== 'guest' && role.code !== 'admin'
      );
      setUserRoles(roles);

      if (callType === TYPE.EDIT) {
        const user = await getUserById(userManageId);
        setUserInfo(user);
        setSelectedRole(roles.find(role => role.id === user?.userRole?.id));
      }
    } catch {
      setUserRoles([]);
    }
  };

  useEffect(getData, []);

  return (
    <div className={style.register}>
      <p className={style.title}>ХЭРЭГЛЭГЧ БҮРТГҮҮЛЭХ</p>
      <Row gutter={80}>
        <Col lg={12}>
          <Form layout="vertical">
            <Form.Item label="Хэрэглэгчийн эрх сонгох" required>
              <AutocompleteSelect
                value={selectedRole?.id}
                showSearch={false}
                data={userRoles}
                disabled={callType === TYPE.EDIT}
                onChange={changeRole}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {selectedRole && (
        <>
          {selectedRole.code.includes('org') ||
          selectedRole.code === 'subAdmin' ? (
            <OrgStore>
              <CheckOrg
                callType={callType}
                userInfo={userInfo}
                selectedRole={selectedRole}
                close={isSuccess => props.close(isSuccess)}
              />
            </OrgStore>
          ) : (
            <RequestAddUser
              callType={callType}
              userInfo={userInfo}
              selectedRole={selectedRole}
              close={isSuccess => props.close(isSuccess)}
            />
          )}
        </>
      )}
    </div>
  );
}
