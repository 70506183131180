import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Spin } from 'antd';

export default isLoading => {
  if (isLoading) {
    return <Spin indicator={<LoadingOutlined />} />;
  }
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      size="small"
      description="Өгөгдөл олдсонгүй"
    />
  );
};
