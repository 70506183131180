import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import 'antd/dist/antd.css';
import '../styles/index.scss';
import { ConfigProvider } from 'antd';
import MN from 'antd/lib/locale/mn_MN';
import PageHeader from '../layout/header/PageHeader';
import ContextProvider from '../context/Tools';

import CheckRegister from '../pages/checkInformation/CheckRegister';
import Admin from '../layout/admin';
import 'moment/locale/mn';
import Counter from '../layout/counter/Counter';

export default function Routes() {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <div />;
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ConfigProvider locale={MN}>
        <ContextProvider>
          <PageHeader hideCollapseIcon />
          <Route path="/checkRegister" component={CheckRegister} />
          <Route path="/" component={Admin} />
          <Counter />
        </ContextProvider>
      </ConfigProvider>
    </Router>
  );
}
