import React, { useState, useRef } from 'react';
import {
  message,
  Button,
  Modal,
  Space,
  Tooltip,
  Progress,
  Popover,
} from 'antd';
import {
  EyeFilled,
  DeleteFilled,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import Axios from '../../service/Axios';
import { getGenerateUuid } from '../../service/Service';
import style from './style.module.scss';
import PDFViewer from '../pdfViewer/PDFViewer';
import { errorCatch } from '../../tools/Tools';
import { popOverContent } from '../PopoverContent';
import { FILE_ACCEPT, FILE_TYPE } from '../../constants/FileConstant';
import { Confirm } from '../Confirm';
import { isExcelFile } from '../../tools/utils';
import {
  deleteFile,
  downloadBlob,
  downloadFile,
  serviceFile,
} from '../../service/fileServices';

export default function FileUpload(props) {
  const hiddenFileInput = useRef(null);
  const { generateUuid, isViewMode, file, type } = props;
  const [isVisibleFileViewer, setIsVisibleFileViewer] = useState(false);
  const [viewPdf, setViewPdf] = useState(null);

  const fileType = type || FILE_TYPE.PDF;
  const inputAcceptType = FILE_ACCEPT[fileType];

  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const cancelRef = useRef();

  const writeFileServer = (selectedFile, uuid) => {
    if (cancelRef.current) {
      cancelRef.current.cancel();
    }
    cancelRef.current = axios.CancelToken.source();
    const data = new FormData();
    const description = selectedFile.description || '';
    data.append('file', selectedFile.fileUpload);
    Axios.post(
      `${serviceFile}?parentUuid=${uuid}&description=${description}`,
      data,
      {
        headers,
        cancelToken: cancelRef.current.token,
        onUploadProgress: progressEvent => {
          selectedFile.percent = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          props.setter(selectedFile);
        },
      }
    )
      .then(result => {
        cancelRef.current = null;
        if (result) {
          selectedFile.id = result.data.id;
          selectedFile.uuid = result.data.uuid;
          selectedFile.name = result.data.name;
          props.setter(selectedFile);
        }
      })
      .catch(error => {
        file.id = null;
        file.percent = 0;
        file.fileUpload = null;
        file.name = null;
        props.setter();
        errorCatch(error);
        throw error;
      });
  };

  const setFile = () => {
    if (!generateUuid) {
      getGenerateUuid().then(res => {
        if (props.setGenerateUuid) {
          props.setGenerateUuid(res.data);
        }
        writeFileServer(file, res.data);
      });
    } else if (file.id) {
      deleteFile(file.id).then(result => {
        if (result) {
          file.id = null;
          file.percent = 0;
          props.setter();
          writeFileServer(file, generateUuid);
        }
      });
    } else {
      writeFileServer(file, generateUuid);
    }
  };

  const onDeleteFile = () => {
    if (cancelRef?.current) {
      cancelRef.current.cancel();
      cancelRef.current = null;
      hiddenFileInput.current.value = null;
      return;
    }
    if (!file.id) {
      if (!file.percent) {
        if (props.remove) {
          props.remove(file);
        }
      } else {
        message.warn('Файл хуулж байхад устгах боломжгүй!');
      }
      return;
    }
    Confirm(() => {
      deleteFile(file.id).then(result => {
        if (result && props.remove) {
          hiddenFileInput.current.value = null;
          props.remove(file);
        }
      });
    });
  };

  const fileUploader = event => {
    if (
      event.target !== null &&
      event.target.files !== null &&
      event.target.files.length > 0
    ) {
      const selectedFile = event.target.files[0];
      // hiddenFileInput.current.value = null;
      // if (!file.description) {
      //   message.warning('Файлын тайлбар оруулна уу!');
      //   return;
      // }
      if (fileType === FILE_TYPE.PDF && !selectedFile.type?.includes('pdf')) {
        message.warning('Зөвхөн PDF өргөтгөлтэй файл оруулна уу!');
        return;
      }
      if (fileType === FILE_TYPE.EXCEL && !isExcelFile(selectedFile?.type)) {
        message.warning('Зөвхөн excel файл оруулна уу!');
        return;
      }
      if (selectedFile.size > 26214400) {
        message.warning('Файлын хэмжээ 25MB - с бага байх ёстой!');
        return;
      }
      file.fileUpload = selectedFile;
      file.name = selectedFile.name;
      setFile();
    }
  };

  const setFileView = selectedFile => {
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = e => {
      setViewPdf(e.target.result);
    };
  };

  const viewFile = () => {
    if (!file.fileUpload && !file.id) {
      message.warn('Сонгосон файл байхгүй байна!');
      return;
    }
    setViewPdf(null);
    setIsVisibleFileViewer(true);
    if (file.fileUpload) {
      setFileView(file.fileUpload);
    } else if (file.uuid) {
      downloadBlob(file.uuid)
        .then(blob => {
          file.fileUpload = blob;
          setFileView(blob);
        })
        .catch(() => {
          setViewPdf('error');
        });
    }
  };

  const clickUploadBtn = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div className={style.fileUpload}>
      <Space size={0} style={{ width: '100%', paddingRight: 4 }}>
        {!isViewMode && (
          <Popover
            placement="topRight"
            content={popOverContent(
              fileType === FILE_TYPE.EXCEL
                ? 'Excel файл хавсаргана уу'
                : 'Бүрдүүлэх баримт бичиг заавал pdf файл байхыг анхаарна уу. /Нэг файлын хэмжээ 25МB-с хэтрэхгүй байх бөгөөд илүү гарсан тохиолдолд compress хийж багасгаж хавсаргана уу. Жишээ нь: https://smallpdf.com/compress-pdf сайт ашиглан багасгах боломжтой./'
            )}
          >
            <div className={style.uploadDiv}>
              <input
                type="file"
                accept={inputAcceptType}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                onChange={fileUploader}
              />
              <Button
                size="small"
                icon={<UploadOutlined />}
                onClick={clickUploadBtn}
              />
            </div>
          </Popover>
        )}
        {file.id ? (
          <>
            <Tooltip placement="left" title="Файл татах">
              <PaperClipOutlined onClick={() => downloadFile(file.uuid)} />
            </Tooltip>
            <span style={{ overflowWrap: 'anywhere' }}>{file.name}</span>
          </>
        ) : (
          <Progress size="small" showInfo={false} percent={file.percent} />
        )}
      </Space>
      <Space>
        {fileType === FILE_TYPE.PDF && (
          <Tooltip placement="left" title="Файл харах">
            <Button size="small" type="secondary" onClick={viewFile}>
              <EyeFilled />
            </Button>
          </Tooltip>
        )}
        {!isViewMode && (
          <Button size="small" disabled={isViewMode} onClick={onDeleteFile}>
            <DeleteFilled />
          </Button>
        )}
        {isVisibleFileViewer && (
          <Modal
            title="Файл харах"
            visible={isVisibleFileViewer}
            footer={false}
            onCancel={() => setIsVisibleFileViewer(false)}
            width="90vw"
            centered
          >
            <div className={style.pdfViewContainer}>
              <PDFViewer viewPdf={viewPdf} />
            </div>
          </Modal>
        )}
      </Space>
    </div>
  );
}
