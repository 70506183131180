import React, { useContext, useState, useEffect } from 'react';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ToolsContext } from '../../context/Tools';
import OrgInfo from './components/orgInfo/OrgInfo';
import style from './style.module.scss';
import Register from './components/Register';
import { TYPE, USER_STEP } from '../../constants/Constant';
import { getUserManages } from './tools/commonMethod';

export default function OrgChoose() {
  const toolsStore = useContext(ToolsContext);

  const [isVisibleRegisterModal, setIsVisibleRegisterModal] = useState();
  const [activeList, setActiveList] = useState([]);
  const [approvedList, setApprovedList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [tempList, setTempList] = useState([]);

  useEffect(() => {
    if (!toolsStore.generalInfo.orgList) return;
    setActiveList(
      toolsStore.generalInfo.orgList.filter(
        user =>
          user.step === USER_STEP.ACTIVE || user.step === USER_STEP.EXPIRED
      )
    );
    setApprovedList(
      toolsStore.generalInfo.orgList.filter(
        user => user.step === USER_STEP.APPROVED && user.isActive !== false
      )
    );
    setRejectedList(
      toolsStore.generalInfo.orgList.filter(
        user => user.step === USER_STEP.REJECTED
      )
    );
    setTempList(
      toolsStore.generalInfo.orgList.filter(
        user => user.step === USER_STEP.TEMP
      )
    );
  }, [toolsStore.generalInfo.orgList]);

  const close = isSuccess => {
    setIsVisibleRegisterModal(false);
    if (isSuccess) {
      getUserManages(toolsStore);
    }
  };

  return (
    <div className={style.orgChooseDiv}>
      <p style={{ fontSize: 16, fontWeight: 'bold', color: '#01418a' }}>
        Шинэ хэрэглэгч болох хүсэлт илгээх бол
        <Button
          type="primary"
          style={{
            margin: 10,
            fontWeight: 'bold',
            width: 80,
          }}
          onClick={() => setIsVisibleRegisterModal(true)}
        >
          ЭНД
        </Button>
        дарна уу.
      </p>
      {approvedList.length > 0 && (
        <>
          <span className="title">БАЙГУУЛЛАГА СОНГОХ</span>
          {approvedList.map((org, index) => (
            <OrgInfo key={index} orgUser={org} />
          ))}
        </>
      )}
      {activeList.length > 0 && (
        <>
          <span className="title">ИЛГЭЭСЭН ХҮСЭЛТ</span>
          {activeList.map((org, index) => (
            <OrgInfo key={index} orgUser={org} refresh={close} />
          ))}
        </>
      )}
      {rejectedList.length > 0 && (
        <>
          <span className="title">ТАТГАЛЗСАН ХҮСЭЛТ</span>
          {rejectedList.map((org, index) => (
            <OrgInfo key={index} orgUser={org} refresh={close} />
          ))}
        </>
      )}
      {tempList.length > 0 && (
        <>
          <span className="title">ТҮР ХАДГАЛСАН ХҮСЭЛТ</span>
          {tempList.map((org, index) => (
            <OrgInfo key={index} orgUser={org} refresh={close} />
          ))}
        </>
      )}
      {isVisibleRegisterModal && (
        <Modal
          title="Шинэ хэрэглэгч болох хүсэлт"
          visible={isVisibleRegisterModal}
          footer={false}
          onCancel={() => close(false)}
          width={700}
        >
          <Register callType={TYPE.NEW} close={close} />
        </Modal>
      )}
    </div>
  );
}
