export const FILE_TYPE = {
  PDF: 'PDF',
  EXCEL: 'EXCEL',
  IMAGE: 'IMAGE',
  PDF_EXCEL: 'PDF_EXCEL',
};

export const FILE_ACCEPT = {
  PDF: '.pdf',
  EXCEL:
    '.xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  IMAGE: 'image/*',
  PDF_EXCEL: '.pdf, .xlsx, .xls, .csv',
};
