import React, { Suspense, useContext } from 'react';
import { Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import { ORGTYPE } from '../../../../../constants/Constant';
import OrgContext from '../../../../../context/OrgContext';
import TusviinBaiguullaga from './components/TusviinBaiguullaga';
import FundedProject from './components/FundedProject';
import LocallyOwnedPlace from './components/LocallyOwnedPlace';
import UnRegisteredInfo from './components/UnRegisteredInfo';
import EquityParticipationCompany from './components/EquityParticipationCompany';
import SpecialFund from './components/SpecialFund';
import FirmNGO from './components/firmNGO';

export default function SectionGeneralInfo() {
  const org = useContext(OrgContext);

  React.useEffect(() => {
    /** Татгалзсан талбаруудыг улааруулах */
    if (isEmpty(org.errorFields)) return;
    org.errorFields.forEach(field => {
      let formItemKey = field;
      if (['registeredAt', 'taxAt', 'reportDataAt'].includes(field)) {
        formItemKey = `${field}Moment`;
      }
      const element = document.querySelector(`label[for='org_${formItemKey}']`);
      if (element) {
        element.style.color = 'red';
        element.style.fontWeight = 600;
      }
    });
  });

  return (
    <div>
      <p className="subtitle">Нэг. ерөнхий мэдээлэл</p>
      <Suspense fallback={<Skeleton active paragraph={{ rows: 8 }} />}>
        {org.mainState.refOrgTypeCode === ORGTYPE.TusviinBaiguullaga && (
          <TusviinBaiguullaga />
        )}
        {org.mainState.refOrgTypeCode === ORGTYPE.FundedProject && (
          <FundedProject />
        )}
        {org.mainState.refOrgTypeCode === ORGTYPE.LocallyOwnedPlace && (
          <LocallyOwnedPlace />
        )}
        {org.mainState.refOrgTypeCode === ORGTYPE.UnRegisteredInfo && (
          <UnRegisteredInfo />
        )}
        {org.mainState.refOrgTypeCode ===
          ORGTYPE.EquityParticipationCompany && <EquityParticipationCompany />}
        {org.mainState.refOrgTypeCode === ORGTYPE.SpecialFund && (
          <SpecialFund />
        )}
        {org.mainState.refOrgTypeCode === ORGTYPE.firmNGO && <FirmNGO />}
      </Suspense>
    </div>
  );
}
