import React, { useContext, useEffect } from 'react';
import Contact from './components/Contact';
import OrgContext from '../../../../../context/OrgContext';
import { isDisabled, isRejectedField } from '../../../CommonMethods';
import { TYPE, USER_TYPES } from '../../../../../constants/Constant';

function SectionContact() {
  const org = useContext(OrgContext);
  const { contactState } = org;

  useEffect(() => {
    contactState.forEach(contact => {
      const findType = USER_TYPES.find(type => type.id === contact.type);
      contact.groupName = findType?.name || '';
    });
  }, []);

  if (org.orgCallType) {
    return (
      <>
        <p className="subtitle">ГУРАВ. ХОЛБОО БАРИХ</p>
        {contactState.map((row, index) => (
          <Contact
            key={index}
            index={index}
            data={row}
            disabled={
              org.orgCallType === TYPE.EDIT ||
              org.orgCallType === TYPE.ESTABLISH ||
              org.orgCallType === TYPE.EDITBYADMIN ||
              isDisabled(`contact-${index}`, org)
            }
            isRejected={isRejectedField(`contact-${index}`, org)}
          />
        ))}
      </>
    );
  }
  return null;
}

export default React.memo(SectionContact);
