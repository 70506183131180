export const PATTERN_MN = /^[^a-z]+$/gi;

export const PATTERN_CYRILLIC = /^[а-яА-ЯүҮөӨёЁ0-9 ,-./]+$/g;

export const PATTERN_NUMBER = /^[0-9]+$/;

export const PATTERN_PHONE = /^([1-9]{1})[\d]{5,}$/;

export const PATTERN_WORKPHONE = /^([1-9]{1})[\d]{5,}$/;

export const PATTERN_ACCOUNT = /^[\d]{9,12}$/;
export const PATTERN_ACCOUNT12 = /^[\d]{12}$/;

export const PATTERN_EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;

export const PATTERN_URL =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/;
// /[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/;
// /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
// /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[a-zA-Z0-9]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[a-zA-Z0-9]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[a-zA-Z0-9]{2,}|www\.[a-zA-Z0-9]+\.[a-zA-Z0-9]{2,})$/;

export const PATTERN_LAT_LNG = /^-?[0-9]{1,3}(?:\.[0-9]{1,20})?$/;
