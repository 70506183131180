import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import * as Fields from '../fields/Fields';
import { ORG_SPECIAL_TYPE } from '../../../../../../constants/Constant';
import OrgContext from '../../../../../../context/OrgContext';

export default function SpecialFund() {
  const org = useContext(OrgContext);

  return (
    <>
      <Row gutter={60}>
        <Col lg={12}>
          <Fields.SpecialFundSelectType />
          <Fields.SpecialFundTypeRadio />
          {org.mainState.refFundFormId !==
            ORG_SPECIAL_TYPE.SpecialAccountInformation && (
            <>
              <Fields.RegisterNo />
              <Fields.CertificateNo />
              <Fields.TaxNo />
            </>
          )}
          <Fields.SpcecialFundName />
          <Fields.RegisteredAt />
          <Fields.TaxAt />
          <Fields.ActivityCode />
          <Fields.ActivityContent />
          <Fields.WhetherHasBranch />
          <Fields.ParentOrganization />
          {org.mainState.refFundFormId ===
            ORG_SPECIAL_TYPE.SpecialCountryCompany && <Fields.LocalActivity />}
          {org.mainState.refFundFormId ===
            ORG_SPECIAL_TYPE.SpecialCountryFactory && <Fields.LocalActivity />}
        </Col>
        <Col lg={12}>
          <Fields.ChooseTez />
          <Fields.InfoTypes />
          <Fields.OrgLocation />
        </Col>
      </Row>
      {org.mainState.refFundFormId ===
        ORG_SPECIAL_TYPE.SpecialCountryCompany && (
        <Fields.EquityPartCompany
          title="Төрийн болон орон нутгийн өмчит компани"
          radioToriinUmch="Төрийн өмчит компани"
          radioOronNutag="Орон нутгийн өмчит компани"
        />
      )}
      {org.mainState.refFundFormId ===
        ORG_SPECIAL_TYPE.SpecialCountryFactory && (
        <Fields.EquityPartCompany
          title="Төрийн болон орон нутгийн өмчит үйлдвэрийн газар"
          radioToriinUmch="Төрийн өмчит үйлдвэрийн газар"
          radioOronNutag="Орон нутгийн өмчит үйлдвэрийн газар"
        />
      )}
    </>
  );
}
