/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Layout } from 'antd';
import shilen from '../../images/suld.svg';
import Menus from './Menus';

const { Sider } = Layout;

export default function MenuSide({ collapsed, menus }) {
  return (
    <Sider
      className="admin-sider-style"
      width="310px"
      theme="light"
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className="flex-center">
        <img src={shilen} className="image" alt="logo" />
        {!collapsed && <a className="name">ШИЛЭН ДАНС</a>}
      </div>
      <Menus menus={menus} collapsed={collapsed} />
    </Sider>
  );
}
