import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Redirect } from 'react-router-dom';
import { Row, Col, Modal, Button } from 'antd';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { useToolsStore } from '../../context/Tools';
import Register from './components/Register';
import style from './style.module.scss';
import RegisterInfo from './components/RegisterInfo';
import OrgChoose from './OrgChoose';
import { TYPE, USER_STEP } from '../../constants/Constant';
import { getUserManages } from './tools/commonMethod';

export default function CheckRegister() {
  const { keycloak } = useKeycloak();
  const toolsStore = useToolsStore();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (keycloak?.authenticated) {
      setShowPopup(true);
      localStorage.setItem('token', keycloak.token);
      if (keycloak.tokenParsed) {
        toolsStore.generalInfo.userInfo = {
          firstname: keycloak.tokenParsed.given_name,
          lastname: keycloak.tokenParsed.family_name,
          email: keycloak.tokenParsed.email,
          register: keycloak.tokenParsed.preferred_username,
        };
        toolsStore.setGeneralInfo({ ...toolsStore.generalInfo });
      }
      getUserManages(toolsStore);
    } else {
      keycloak?.login();
    }
  }, []);

  if (!keycloak?.authenticated) {
    return 'Not authenticated';
  }

  if (!toolsStore.generalInfo.orgList) {
    return null;
  }

  if (
    toolsStore.selectedUser &&
    toolsStore.selectedUser.step === USER_STEP.APPROVED
  ) {
    let redirectPath = localStorage.getItem('redirect-path');
    if (redirectPath) {
      localStorage.removeItem('redirect-path');
    } else {
      redirectPath = '/dashboard';
    }
    return <Redirect to={redirectPath} />;
  }

  return (
    <>
      <div className={style.CheckInformationStyle}>
        <Modal
          title="Анхааруулга"
          visible={showPopup}
          onOk={() => setShowPopup(false)}
          onCancel={() => setShowPopup(false)}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => setShowPopup(false)}
            >
              Цуцлах
            </Button>,
          ]}
        >
          <p style={{ color: 'red' }}>
            2023 оны Шилэн дансны мэдээлэлд Хөндлөнгийн аудит хийх үед
            байгууллагууд мэдээлэл оруулах хүмүүсийг сонгоогүй, зарим маягтыг
            бөглөхгүй гэж сонгосноос шалтгаалан тухайн байгууллагын оруулах
            мэдээлэл дутах алдаа дутагдал маш олон илэрсэн тул СОНГООГҮЙ БАЙСАН
            МАЯГТУУДЫГ ил гаргах тохиргоог систем дээр нэгдсэн байдлаар хийсэн.
          </p>
          <p>
            Иймд та 2023 оны мэдээллийг шалгаж оруулдаггүй маягтууд нэмэгдсэн
            бол ОРУУЛАХ МЭДЭЭЛЭЛ БАЙХГҮЙ гэж сонгон хадгалж ил тод болгоорой.
            Ингэж сонгосон тохиолдолд уг маягт хугацаандаа орсон төлөвөөр
            харагдах болно. 2024 онд бас уг маягтуудад ОРУУЛАХ МЭДЭЭЛЭЛ БАЙХГҮЙ
            гэж сонгож явна уу. Хэрэв оруулах мэдээлэл байгаа бол мэдээллээ үнэн
            зөв оруулан хадгалж ил тод болгоно уу /Мэдээлэл хугацаа хоцорсон
            төлөвөөр хадгалагдана/.
          </p>
        </Modal>
        <Row gutter={60}>
          <Col xl={9} style={{ height: '100%' }}>
            <RegisterInfo />
          </Col>
          <Col
            xl={15}
            style={{
              borderLeft: '1px solid #ddd',
              height: '100%',
              overflow: 'auto',
            }}
          >
            {toolsStore.generalInfo.orgList?.length > 0 && <OrgChoose />}
            {toolsStore.generalInfo.orgList?.length === 0 && (
              <Register
                callType={TYPE.NEW}
                close={isSuccess => {
                  if (isSuccess) {
                    getUserManages(toolsStore);
                  }
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
