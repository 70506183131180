import React from 'react';
import { Row, Col, Typography } from 'antd';
import structure from '../../../../images/structure.svg';

const { Title, Paragraph } = Typography;

export default function SlideThree() {
  return (
    <div className="slide-main">
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <img src={structure} alt="logs" height="160px" width="160px" />
      </div>
      <br />
      <Title level={5} align="center">
        МАЯГТЫН БҮТЭЦ
      </Title>
      <br />
      <Paragraph>
        Мэдээлэх үүрэг бүхий хуулийн этгээдийн бүртгэлийн ерөнхий мэдээлэл нь
        үндсэн хоёр хэсгээс бүрдэнэ. Үүнд:
      </Paragraph>
      <br />
      <Row gutter={50}>
        <Col md={12}>
          <Paragraph>
            <p
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              Баталгаажуулах шаардлагатай мэдээлэл
            </p>
            Оруулсан болон засвар, өөрчлөлт хийсэн мэдээллийг хавсаргасан
            материалтай нь Сангийн Яамны ШДНЦХ-ны нэгдсэн админ тулган
            баталгаажуулдаг
          </Paragraph>
        </Col>
        <Col md={12}>
          <Paragraph>
            <p
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              Баталгаажуулах шаардлагагүй мэдээлэл
            </p>
            Байгууллагын админ өөрөө засварлах боломжтой мэдээлэл бөгөөд Сангийн
            яамаар баталгаажуулах шаардлагагүй.
          </Paragraph>
        </Col>
      </Row>
      <Paragraph style={{ display: 'flex', marginTop: '20px' }}>
        <div className="rcorners2">!</div>
        <div className="attention-text">
          Баталгаажуулах шаардлагатай маягтын мэдээллийг бөглөсөн эсэхийг
          Нэгдсэн админ хянан баталгаажуулсны дараа байгууллагын оруулах
          мэдээллийн маягт идэвхжинэ.
        </div>
      </Paragraph>

      <Paragraph style={{ display: 'flex' }}>
        <div className="rcorners2">!</div>
        <div className="attention-text">
          Бүртгэлийн мэдээлэл баталгаажсанаар санхүүжилтийн эрх болон данс
          нээгдэх тул та бүхэн бүртгэл баталгаажих хугацааг урьдчилан тооцох
          ёстойг анхаарна уу.
        </div>
      </Paragraph>
    </div>
  );
}
