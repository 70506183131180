import { errorCatch } from '../tools/Tools';
import Axios from './Axios';
import { deleteService, getRequest } from './Service';

const serviceFileBaseUrl = '/gap-file-service';

const serviceFile = `${serviceFileBaseUrl}/files`;
const serviceFileDownload = `${serviceFile}/download`;

export const getFiles = async params => await getRequest(serviceFile, params);

export const deleteFile = async id =>
  await deleteService(`${serviceFile}/${id}`);

export const getFileURL = uuid =>
  `${process.env.REACT_APP_BASE_URL}gap-file-service/files/download?uuid=${uuid}`;

export const downloadBlob = async uuid => {
  const response = await Axios.get(serviceFileDownload, {
    params: { uuid },
    responseType: 'blob',
  }).catch(error => {
    errorCatch(error);
    throw error;
  });
  return response?.data;
};

export const downloadFile = uuid => {
  const link = document.createElement('a');
  link.href = getFileURL(uuid);
  link.click();
};

export async function writeFileServer(selectedFile, generateUuid, fileName) {
  const data = new FormData();
  data.append('file', selectedFile);
  return await Axios.post(
    `${serviceFile}?parentUuid=${generateUuid}&description=${fileName}`,
    data,
    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
  ).catch(error => {
    errorCatch(error);
    throw error;
  });
}

export { serviceFile };
