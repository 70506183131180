import { ORG_STEP } from '../constants/Constant';
import { deleteService, getRequest, postService, putService } from './Service';

const serviceOrgBaseUrl = '/gap-organization-service';

const serviceOrgInfoUrl = `${serviceOrgBaseUrl}/orgs`;
const serviceOrgFindWithRefCentralBudgetGovernor = `${serviceOrgInfoUrl}/findWithRefCentralBudgetGovernorIdIsNull`;
const serviceOrganizationByFields = `${serviceOrgInfoUrl}/findOrgListByRequestedFields`;
const serviceCreateOrganization = `${serviceOrgInfoUrl}/save`;
const serviceUpdateOrganization = `${serviceOrgInfoUrl}/update`;
const serviceProfileOrganization = `${serviceOrgInfoUrl}/profile`;
const serviceProceedOrganization = `${serviceOrgInfoUrl}/proceed`; // Татгалзсан болон Түр хадгалсан хүсэлтээ бүр хадгалах
const serviceRequestOrganization = `${serviceOrgInfoUrl}/request`;
const serviceApproveOrganization = `${serviceOrgInfoUrl}/approve`;
const serviceRejectOrganization = `${serviceOrgInfoUrl}/reject`;
const serviceTemporaryOrg = `${serviceOrgInfoUrl}/draft`; // Түр хадгалах - эхний удаа
const serviceUpdateTemporaryOrg = `${serviceOrgInfoUrl}/temporary`; // Түр хадгалсан маягтыг үргэлжлүүлэх
const serviceFindTemporaryById = `${serviceOrgInfoUrl}/findTemporaryById`; // Түр хадгалсан маягт харах
const serviceOrgStatisticsByYear = `${serviceOrgInfoUrl}/dashboardOfWishGroupByYear`;
const servicePortalDashboardOfRefType = `${serviceOrgInfoUrl}/portalDashboardOfRefType`;
const serviceDashboardOfRefOrgType = `${serviceOrgInfoUrl}/dashboardOfRefOrgType`;
const serviceDashboardOfWish = `${serviceOrgInfoUrl}/dashboardOfWish`;
const serviceDashboardByWishOfStep = `${serviceOrgInfoUrl}/dashboardByWishOfStep`;
const serviceOrgBankAccount = `${serviceOrgBaseUrl}/orgBankAccounts`;
const serviceOrgBankAccountType = `${serviceOrgBankAccount}/type`;
const serviceOrgBankAccountSubAdmin = `${serviceOrgBankAccount}/findAllBySubAdmin`;
const serviceOrgBankAccountSaveAll = `${serviceOrgBankAccount}/saveAll`;
const serviceOrgBankReportAccounts = `${serviceOrgBankAccount}/reportAccounts`;
const serviceOrgContact = `${serviceOrgBaseUrl}/orgContacts`;
const serviceOrgContactSaveAll = `${serviceOrgContact}/saveAll`;

// ---- Organization ----
export const getOrgs = async params =>
  await getRequest(serviceOrgInfoUrl, params);

export const getOrganization = async id =>
  await getRequest(`${serviceOrgInfoUrl}/${id}`);

export const createOrganization = async values =>
  await postService(serviceCreateOrganization, values);

export const updateOrganization = async (id, values) =>
  await putService(`${serviceUpdateOrganization}/${id}`, values);

export const profileOrganization = async (id, values) =>
  await putService(`${serviceProfileOrganization}/${id}`, values);

export const proceedOrganization = async (id, values) =>
  await putService(`${serviceProceedOrganization}/${id}`, values);

export const requestOrganization = async (id, values) =>
  await putService(`${serviceRequestOrganization}/${id}`, values);

export const approveOrganization = async (id, values) =>
  await putService(`${serviceApproveOrganization}/${id}`, values);

export const rejectOrganization = async (id, values) =>
  await putService(`${serviceRejectOrganization}/${id}`, values);

export const createTemporaryOrg = async values =>
  await postService(serviceTemporaryOrg, values);

export const getTemporaryOrgById = async id =>
  await getRequest(`${serviceFindTemporaryById}/${id}`);

export const updateTempOrg = async (id, values) =>
  await putService(`${serviceUpdateTemporaryOrg}/${id}`, values);

export const getOrgStatistics = async () =>
  await getRequest(serviceOrgStatisticsByYear);

export const getPortalDashboardOfRefType = async lazyParams =>
  await getRequest(servicePortalDashboardOfRefType, lazyParams);

export const getDashboardOfRefOrgType = async lazyParams =>
  await getRequest(serviceDashboardOfRefOrgType, lazyParams);

export const getDashboardOfWish = async lazyParams =>
  await getRequest(serviceDashboardOfWish, lazyParams);

export const getDashboardByWishOfStep = async wish =>
  await getRequest(`${serviceDashboardByWishOfStep}/${wish}`);

export const getOrgBankAccounts = async params =>
  await getRequest(serviceOrgBankAccount, params);

export const createOrgBankAccount = async values =>
  await postService(serviceOrgBankAccount, values);

export const updateOrgBankAccount = async (id, values) =>
  await putService(`${serviceOrgBankAccount}/${id}`, values);

export const updatePrimaryOrgBankAccount = async values =>
  await putService(serviceOrgBankAccountType, values);

export const saveOrgBankAccounts = async values =>
  await postService(serviceOrgBankAccountSaveAll, values);

export const getOrgBankReportAccounts = async params =>
  await getRequest(serviceOrgBankReportAccounts, params);

export const getOrgContacts = async params =>
  await getRequest(serviceOrgContact, params);

export const createOrgContact = async values =>
  await postService(serviceOrgContact, values);

export const updateOrgContact = async (id, values) =>
  await putService(`${serviceOrgContact}/${id}`, values);

export const deleteOrgContact = async id =>
  await deleteService(`${serviceOrgContact}/${id}`);

export const saveOrgContacts = async values =>
  await postService(serviceOrgContactSaveAll, values);

export const filterActiveOrg = {
  status: true,
  isActive: true,
  isWish: false,
  step: ORG_STEP.APPROVED,
};

export const getActiveOrganizationsByFields = async (params, cancelRef) => {
  const search = { ...filterActiveOrg, ...params?.search };
  const newParams = { ...params, search };
  return await getRequest(serviceOrganizationByFields, newParams, cancelRef);
};

export const getActiveOrgs = async (params, cancelRef) => {
  const search = { ...filterActiveOrg, ...params?.search };
  const newParams = { ...params, search };
  return await getRequest(serviceOrgInfoUrl, newParams, cancelRef);
};

export const getOrganizationsByFields = async (params, cancelRef) => {
  const search = { ...filterActiveOrg, ...params?.search };
  const newParams = { ...params, search };
  if (!params?.requestedFields) {
    newParams.requestedFields = 'id,name';
  }
  return await getRequest(serviceOrgInfoUrl, newParams, cancelRef);
};

export {
  serviceOrgInfoUrl,
  serviceOrganizationByFields,
  serviceOrgContact,
  serviceOrgBankAccount,
  serviceOrgBankAccountSubAdmin,
  serviceOrgFindWithRefCentralBudgetGovernor,
};
