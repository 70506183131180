import { isNil } from 'lodash';
import { getGetUsersByToken } from '../../../service/userService';

export const getUserManages = toolsStore => {
  toolsStore.setIsShowLoader(true);

  getGetUsersByToken()
    .then(result => {
      if (isNil(toolsStore.generalInfo.orgList) && result.length) {
        toolsStore.generalInfo.user = result[0].user;
        // if (
        //   result.length === 1 &&
        //   result[0].step === USER_STEP.APPROVED &&
        //   result[0].isActive !== false
        // ) {
        //   toolsStore.setterSelectedUser(result[0]);
        // }
      }
      toolsStore.generalInfo.orgList = result;
      toolsStore.setGeneralInfo({ ...toolsStore.generalInfo });
    })
    .finally(() => {
      toolsStore.setIsShowLoader(false);
    });
};
