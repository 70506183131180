/* eslint-disable import/no-cycle */
import React from 'react';
import { Modal } from 'antd';
import OrganizationForm from '../../organizatioinRegister/OrganizationForm';
import { OrgStore } from '../../../context/OrgContext';
import { TYPE } from '../../../constants/Constant';
import { Confirm } from '../../../components/Confirm';

export default function OrgModal(props) {
  const { orgCallType, orgData, isModalVisibleOrg, close } = props;

  const onExit = () => {
    if (orgCallType === TYPE.NEW || orgCallType === TYPE.DRAFT) {
      Confirm(
        () => close(false),
        'Та маягтаа хадгалахгүй гарахдаа итгэлтэй байна уу?'
      );
    } else {
      close(false);
    }
  };

  return (
    <Modal
      className="org-modal"
      visible={isModalVisibleOrg}
      closable
      onCancel={onExit}
      width="85%"
      height="100%"
      footer={false}
    >
      <OrgStore>
        <OrganizationForm
          orgCallType={orgCallType}
          orgData={orgData}
          close={close}
        />
      </OrgStore>
    </Modal>
  );
}
