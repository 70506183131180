import FileResizer from 'react-image-file-resizer';

export const sortByPriority = (first, second) => {
  if (first.priority) {
    return first.priority - second.priority;
  }
  return 0;
};

export const isExcelFile = type =>
  (type || '').includes('sheet') || (type || '').includes('excel');

const dataURLtoFile = (url, filename) => {
  const arr = url.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bufferData = Buffer.from(arr[1], 'base64');
  return new File([bufferData], filename, { type: mime });
};

export const resizeImage = (file, name) =>
  new Promise(resolve => {
    FileResizer.imageFileResizer(
      file,
      160,
      160,
      'PNG',
      100,
      0,
      uri => {
        const imgObj = {
          uri,
          file: dataURLtoFile(uri, `${name}.png`),
        };
        resolve(imgObj);
      },
      'base64'
    );
  });
