import React from 'react';
import loadable from 'react-loadable';
import Breadcrump from './Breadcrump';
import Loader from '../loader/Loader';
import { OrgStore } from '../context/OrgContext';
import OrgDashboardContextProvider from '../context/OrgDashboardContext';

const Dashboard = loadable({
  loader: () => import('../pages/dashboard/Dashboard'),
  loading: Loader,
});

const OrgRequestDashboard = loadable({
  loader: () => import('../pages/orgRequestDashboard/OrgRequestDashboard'),
  loading: Loader,
});

const RefTTZ = loadable({
  loader: () => import('../pages/resgister/RefTTZ'),
  loading: Loader,
});

const RefTBONUK = loadable({
  loader: () => import('../pages/resgister/RefTBONUK'),
  loading: Loader,
});

const RefTOUG = loadable({
  loader: () => import('../pages/resgister/RefTOUG'),
  loading: Loader,
});

const RefONOUG = loadable({
  loader: () => import('../pages/resgister/RefONOUG'),
  loading: Loader,
});

const RefOrgType = loadable({
  loader: () => import('../pages/resgister/RefOrgType'),
  loading: Loader,
});

const RefLocation = loadable({
  loader: () => import('../pages/resgister/RefLocation'),
  loading: Loader,
});

const RefISIC = loadable({
  loader: () => import('../pages/resgister/RefISIC'),
  loading: Loader,
});

const RefFUNDCLASSIFICATION = loadable({
  loader: () => import('../pages/resgister/RefFundClassifications'),
  loading: Loader,
});

const RefUlsTEZ = loadable({
  loader: () => import('../pages/resgister/RefUlsTEZ'),
  loading: Loader,
});

const RefOrgActivity = loadable({
  loader: () => import('../pages/resgister/RefOrgActivity'),
  loading: Loader,
});

const RefReportSelector = loadable({
  loader: () =>
    import('../pages/resgister/refReportSelector/RefReportSelector'),
  loading: Loader,
});

const RefBank = loadable({
  loader: () => import('../pages/resgister/RefBank'),
  loading: Loader,
});

const OrgProfile = loadable({
  loader: () => import('../pages/baiguullagiinMedeelel/OrgProfile'),
  loading: Loader,
});

const OrgRequest = loadable({
  loader: () => import('../pages/orgRequest/OrgRequestList'),
  loading: Loader,
});

const OrgList = loadable({
  loader: () => import('../pages/orgInfoList/OrgList'),
  loading: Loader,
});

const SubOrg = loadable({
  loader: () => import('../pages/subOrg/subOrg'),
  loading: Loader,
});

const EndedOrgList = loadable({
  loader: () => import('../pages/orgInfoList/EndedOrgList'),
  loading: Loader,
});

const OrgBankAccounts = loadable({
  loader: () => import('../pages/orgInfoList/OrgBankAccounts'),
  loading: Loader,
});

const MenuSettings = loadable({
  loader: () => import('../pages/settings/MenuSettings'),
  loading: Loader,
});

const Users = loadable({
  loader: () => import('../pages/userRole/Users'),
  loading: Loader,
});

const Roles = loadable({
  loader: () => import('../pages/userRole/Roles'),
  loading: Loader,
});

const UserControl = loadable({
  loader: () => import('../pages/userControl/UserControl'),
  loading: Loader,
});

const ReportGroup = loadable({
  loader: () => import('../pages/report/ReportGroup'),
  loading: Loader,
});

const ReportRecurringType = loadable({
  loader: () => import('../pages/report/ReportRecurringType'),
  loading: Loader,
});

const ReportRecurring = loadable({
  loader: () => import('../pages/report/ReportRecurring'),
  loading: Loader,
});

const ReportAdjustInformation = loadable({
  loader: () => import('../pages/report/ReportFormType'),
  loading: Loader,
});

const ReportFormSettings = loadable({
  loader: () => import('../pages/report/ReportFormSettings'),
  loading: Loader,
});

const ReportFormReccuring = loadable({
  loader: () => import('../pages/report/reportFormRecurring'),
  loading: Loader,
});

const ReportExtendTime = loadable({
  loader: () => import('../pages/report/ReportExtendTime'),
  loading: Loader,
});

const ReportOrganization = loadable({
  loader: () => import('../pages/report/ReportOrganization'),
  loading: Loader,
});

const ReportOrganizationData = loadable({
  loader: () => import('../pages/report/ReportOrganizationData'),
  loading: Loader,
});

const ReportApply = loadable({
  loader: () => import('../pages/report/reportApply/reportApply'),
  loading: Loader,
});

const ReportAudit = loadable({
  loader: () => import('../pages/report/reportAudit/reportAudit'),
  loading: Loader,
});

const Feedback = loadable({
  loader: () => import('../pages/Feedback/Feedback'),
  loading: Loader,
});

const Letter = loadable({
  loader: () => import('../pages/letter/Letter'),
  loading: Loader,
});

const CategoryList = loadable({
  loader: () => import('../pages/helPage/categoryList/categoryList'),
  loading: Loader,
});

const HelpUploadFile = loadable({
  loader: () => import('../pages/helPage/helpUploadFile/helpUploadFile'),
  loading: Loader,
});

const TheLawControl = loadable({
  loader: () =>
    import('../pages/portalControl/learningControl/theLaw/ThelawControl'),
  loading: Loader,
});

const HandBook = loadable({
  loader: () =>
    import('../pages/portalControl/learningControl/handBook/HandBook'),
  loading: Loader,
});

const BudgetFinances = loadable({
  loader: () =>
    import(
      '../pages/portalControl/learningControl/researchReport/budgetFinance/BudgetFinance'
    ),
  loading: Loader,
});

const BookResearchReport = loadable({
  loader: () =>
    import(
      '../pages/portalControl/learningControl/researchReport/bookResearchReport/bookResearchReport'
    ),
  loading: Loader,
});

const VideoLearning = loadable({
  loader: () =>
    import(
      '../pages/portalControl/learningControl/videoLearning/videoLearning'
    ),
  loading: Loader,
});

const Glossary = loadable({
  loader: () =>
    import('../pages/portalControl/learningControl/glossary/Glossary'),
  loading: Loader,
});

const Banner = loadable({
  loader: () => import('../pages/portalControl/homePageControl/banner/Banner'),
  loading: Loader,
});

const Footer = loadable({
  loader: () => import('../pages/portalControl/homePageControl/footer/Footer'),
  loading: Loader,
});

const HighlightMenu = loadable({
  loader: () =>
    import(
      '../pages/portalControl/homePageControl/highlightMenu/HighlightMenu'
    ),
  loading: Loader,
});

const Links = loadable({
  loader: () => import('../pages/portalControl/homePageControl/links/Links'),
  loading: Loader,
});
const News = loadable({
  loader: () => import('../pages/portalControl/homePageControl/news/News'),
  loading: Loader,
});
const PortalPerformance = loadable({
  loader: () =>
    import('../pages/portalControl/portalPerformance/PortalPerformance'),
  loading: Loader,
});
const PortalBudgetPerformance = loadable({
  loader: () =>
    import(
      '../pages/portalControl/portalBudgetPerformance/PortalBudgetPerformance'
    ),
  loading: Loader,
});

const IntegrationMatch = loadable({
  loader: () => import('../pages/integration/IntegrationMatch'),
  loading: Loader,
});

const IntegrationTimeSetting = loadable({
  loader: () => import('../pages/integration/IntegrationTimeSetting'),
  loading: Loader,
});

const Comment = loadable({
  loader: () => import('../pages/comment/Comment'),
  loading: Loader,
});

const UserList = loadable({
  loader: () => import('../pages/log/UserList'),
  loading: Loader,
});

const OrgUserRequest = loadable({
  loader: () => import('../pages/log/OrgUserRequest'),
  loading: Loader,
});

const UserAccessLog = loadable({
  loader: () => import('../pages/log/UserAccessLog'),
  loading: Loader,
});

const EmailLog = loadable({
  loader: () => import('../pages/log/EmailLog'),
  loading: Loader,
});

const IntegrationLog = loadable({
  loader: () => import('../pages/log/IntegrationLog'),
  loading: Loader,
});

const IntegrationLogByOrg = loadable({
  loader: () => import('../pages/log/IntegrationLogByOrg'),
  loading: Loader,
});

const ReportWorkedLog = loadable({
  loader: () => import('../pages/log/ReportWorkedLog'),
  loading: Loader,
});

const ReportOrganizationRecurringLog = loadable({
  loader: () => import('../pages/log/ReportOrganizationRecurringLog'),
  loading: Loader,
});

const ReportFormGroupLog = loadable({
  loader: () => import('../pages/log/ReportFormGroupLog'),
  loading: Loader,
});

const ReportOrgWorkedLog = loadable({
  loader: () => import('../pages/log/ReportOrgWorkedLog'),
  loading: Loader,
});

const Page = ({ route }) => {
  const PageContent = () => {
    switch (route.code.toLowerCase()) {
      case 'dashboard'.toLowerCase():
        return <Dashboard />;
      case 'orgRequestDashboard'.toLowerCase():
        return (
          <OrgDashboardContextProvider>
            <OrgRequestDashboard />
          </OrgDashboardContextProvider>
        );
      /** Лавлах */
      case 'refTTZ'.toLowerCase():
        return <RefTTZ />;
      case 'refTBONUK'.toLowerCase():
        return <RefTBONUK />;
      case 'refTOUG'.toLowerCase():
        return <RefTOUG />;
      case 'refONOUG'.toLowerCase():
        return <RefONOUG />;
      case 'refOrgType'.toLowerCase():
        return <RefOrgType />;
      case 'refCity'.toLowerCase():
        return <RefLocation />;
      case 'refISIC'.toLowerCase():
        return <RefISIC />;
      case 'refFUNDCLASSIFICATION'.toLowerCase():
        return <RefFUNDCLASSIFICATION />;
      case 'refUlsTEZ'.toLowerCase():
        return <RefUlsTEZ />;
      case 'refOrgActivity'.toLowerCase():
        return <RefOrgActivity />;
      case 'refReportSelector'.toLowerCase():
        return <RefReportSelector />;
      case 'refBank'.toLowerCase():
        return <RefBank />;

      /** Байгууллагын мэдээлэл */
      case 'orgProfile'.toLowerCase():
        return <OrgProfile />;
      case 'orgRequest'.toLowerCase():
        return (
          <OrgStore>
            <OrgRequest />
          </OrgStore>
        );
      case 'orgList'.toLowerCase():
        return <OrgList />;
      case 'endedOrgList'.toLowerCase():
        return <EndedOrgList />;
      case 'orgBankAccounts'.toLowerCase():
        return <OrgBankAccounts />;
      case 'subOrg'.toLowerCase():
        return <SubOrg />;

      /** тохиргоо */
      case 'menuSettings'.toLowerCase():
        return <MenuSettings />;

      /** Хэрэглэгчийн эрхийн тохиргоо */
      case 'users'.toLowerCase():
        return <Users />;
      case 'roles'.toLowerCase():
        return <Roles />;
      case 'userControl'.toLowerCase():
        return <UserControl />;

      /** Тайлан */
      case 'reportGroup'.toLowerCase():
        return <ReportGroup />;
      case 'reportRecurringType'.toLowerCase():
        return <ReportRecurringType />;
      case 'reportRecurring'.toLowerCase():
        return <ReportRecurring />;
      case 'ReportFormType'.toLowerCase():
        return <ReportAdjustInformation />;
      case 'reportForm'.toLowerCase():
        return <ReportFormSettings />;
      case 'reportFormRecurring'.toLowerCase():
        return <ReportFormReccuring />;
      case 'reportExtendTime'.toLowerCase():
        return <ReportExtendTime />;
      case 'reportOrganization'.toLowerCase():
        return <ReportOrganization />;
      case 'reportOrganizationData'.toLowerCase():
        return <ReportOrganizationData />;
      case 'reportApply'.toLowerCase():
        return <ReportApply />;
      case 'reportAudit'.toLowerCase():
        return <ReportAudit />;

      /** Иргэдийн хяналт */
      case 'feedback'.toLowerCase():
        return <Feedback />;

      /** Захидал */
      case 'letter'.toLowerCase():
        return <Letter />;

      /** тусламж */
      case 'categoryList'.toLowerCase():
        return <CategoryList />;
      case 'helpUploadFile'.toLowerCase():
        return <HelpUploadFile />;

      /** Портал удирдлага */

      /** Суралцахуй */
      case 'theLawControl'.toLowerCase():
        return <TheLawControl />;
      case 'handBook'.toLowerCase():
        return <HandBook />;
      case 'budgetFinances'.toLowerCase():
        return <BudgetFinances />;
      case 'bookResearchReport'.toLowerCase():
        return <BookResearchReport />;
      case 'videoLearning'.toLowerCase():
        return <VideoLearning />;
      case 'glossary'.toLowerCase():
        return <Glossary />;

      /** Нүүр хуудас */
      case 'banner'.toLowerCase():
        return <Banner />;
      case 'footer'.toLowerCase():
        return <Footer />;
      case 'highlightMenu'.toLowerCase():
        return <HighlightMenu />;
      case 'links'.toLowerCase():
        return <Links />;
      case 'news'.toLowerCase():
        return <News />;

      /** Портал үр дүнгийн үзүүлэлт */
      case 'portalPerformance'.toLowerCase():
        return <PortalPerformance />;

      /** Портал Төсөв, гүйцэтгэл */
      case 'portalBudgetPerformance'.toLowerCase():
        return <PortalBudgetPerformance />;

      /** Integration */
      case 'integrationMatch'.toLowerCase():
        return <IntegrationMatch />;
      case 'integrationTimeSetting'.toLowerCase():
        return <IntegrationTimeSetting />;

      /** Сэтгэгдэл */
      case 'comment'.toLowerCase():
        return <Comment />;

      /** Log */
      case 'userList'.toLowerCase():
        return <UserList />;
      case 'OrgUserRequest'.toLowerCase():
        return <OrgUserRequest />;
      case 'userAccessLog'.toLowerCase():
        return <UserAccessLog />;
      case 'emailLog'.toLowerCase():
        return <EmailLog />;
      case 'IntegrationLogByOrg'.toLowerCase():
        return <IntegrationLogByOrg />;
      case 'IntegrationLog'.toLowerCase():
        return <IntegrationLog />;
      case 'ReportWorkedLog'.toLowerCase():
        return <ReportWorkedLog />;
      case 'ReportOrganizationRecurringLog'.toLowerCase():
        return <ReportOrganizationRecurringLog />;
      case 'ReportFormGroupLog'.toLowerCase():
        return <ReportFormGroupLog />;
      case 'ReportOrgWorkedLog'.toLowerCase():
        return <ReportOrgWorkedLog />;

      default:
        return null;
    }
  };

  return (
    <>
      {route.parent && <Breadcrump route={route} />}
      {PageContent()}
    </>
  );
};

export default Page;
