import { Modal, Carousel } from 'antd';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import React from 'react';
import SlideOne from './components/SlideOne';
import SlideTwo from './components/SlideTwo';
import SlideThree from './components/SlideThree';
import SlideFour from './components/SlideFour';
import SlideFive from './components/SlideFive';
import style from './style.module.scss';

export default function AlertDialogs(props) {
  const { visible } = props;

  return (
    <>
      <Modal
        className="alert-modal"
        visible={visible}
        footer={false}
        closable={false}
        bodyStyle={{ padding: 0 }}
        width="700px"
        centered
        // style={{ borderRadius: '1rem' }}
        onCancel={() => props.close(false)}
      >
        <Carousel
          arrows
          nextArrow={<RightCircleFilled style={{ color: '#6A63F6' }} />}
          prevArrow={<LeftCircleFilled style={{ color: '#6A63F6' }} />}
          dots={{ className: 'carousel-dot' }}
          infinite={false}
        >
          <div>
            <div className={style.contentStyle}>
              <SlideOne />
            </div>
          </div>
          <div>
            <div className={style.contentStyle}>
              <SlideTwo />
            </div>
          </div>
          <div>
            <div className={style.contentStyle}>
              <SlideThree />
            </div>
          </div>
          <div>
            <div className={style.contentStyle}>
              <SlideFour />
            </div>
          </div>
          <div>
            <div className={style.contentStyle}>
              <SlideFive close={isSuccess => props.close(isSuccess)} />
            </div>
          </div>
        </Carousel>
      </Modal>
      ,
    </>
  );
}
