/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row, Form, Button, message } from 'antd';
import moment, { isMoment } from 'moment';
import { isArray } from 'lodash';
import OrgContext from '../../context/OrgContext';
import { getGenerateUuid } from '../../service/Service';
import {
  certificateNo,
  fillOrgInfo,
  getCurrentPositionByMap,
  regNo,
  taxNo,
} from './CommonMethods';
import Header from './sections/header/Header';
import SectionGeneralInfo from './sections/formContent/sectionGeneralInfo/SectionGeneralInfo';
import SectionFile from './sections/formContent/SectionFile';
import SectionContact from './sections/formContent/sectionContact/SectionContact';
import ChangeInfo from '../orgRequest/components/ChangeInfo';
import { ToolsContext } from '../../context/Tools';
import {
  errorCatch,
  isChangedData,
  isEmptyObject,
  requireFieldFocus,
} from '../../tools/Tools';
import { Confirm, Success } from '../../components/Confirm';
import style from './style.module.scss';
import { DATEFORMAT, ORGTYPE, TYPE, USER_TYPE } from '../../constants/Constant';
import Description from '../../components/description/Description';
import {
  approveOrganization,
  createOrganization,
  createTemporaryOrg,
  getOrganization,
  getTemporaryOrgById,
  proceedOrganization,
  rejectOrganization,
  updateOrganization,
  updateTempOrg,
} from '../../service/organizationServices';

let changedData;

const layout = {
  labelCol: { span: 11 },
  wrapperCol: { span: 13 },
};

export default function OrganizationForm(props) {
  const toolsStore = useContext(ToolsContext);
  const org = useContext(OrgContext);

  const { orgCallType, orgData } = props;

  const [isModalVisibleChangeInfo, setIsModalVisibleChangeInfo] =
    useState(false);
  const [isVisibleDescription, setIsVisibleDescription] = useState(false);

  const initData = async () => {
    org.orgCallType = props.orgCallType;
    org.setOrgCallType(props.orgCallType);

    if (orgCallType === TYPE.NEW) {
      getCurrentPositionByMap(org);
      org.form.resetFields();
      org.form.setFieldsValue({
        lastname0: toolsStore.generalInfo.userInfo.lastname,
        firstname0: toolsStore.generalInfo.userInfo.firstname,
        email0: toolsStore.generalInfo.userInfo.email,
        // reportDataAt tur nemev - 2023-01-01 ognoogoor
        reportDataAtMoment: moment('2023-01-01', DATEFORMAT),
        longitude: org.mainState.longitude,
        latitude: org.mainState.latitude,
      });
      const contactAdmin = org.contactState.find(
        user => user.type === USER_TYPE.ORG_ADMIN
      );
      if (contactAdmin) {
        contactAdmin.lastname = toolsStore.generalInfo.userInfo.lastname;
        contactAdmin.firstname = toolsStore.generalInfo.userInfo.firstname;
        contactAdmin.email = toolsStore.generalInfo.userInfo.email;
        org.setContactState([...org.contactState]);
      }
      org.setFileState([
        {
          key: 0,
          description:
            'Хуулийн этгээдийн улсын бүртгэлийн гэрчилгээний хуулбар',
        },
        {
          key: 1,
          description: 'Бусад нэмэлт өөрчлөлтийг нотлох тушаал, шийдвэр',
        },
      ]);
    } else {
      let orgResult = null;
      toolsStore.setIsShowLoader(true);
      const orgId =
        orgCallType === TYPE.EDIT || orgCallType === TYPE.ESTABLISH
          ? toolsStore.selectedUser.orgId
          : orgData.id;
      try {
        if (orgCallType === TYPE.DRAFT) {
          orgResult = await getTemporaryOrgById(orgId);
        } else {
          orgResult = await getOrganization(orgId);
        }
        fillOrgInfo(org, orgResult);
        toolsStore.setIsShowLoader(false);
      } catch {
        toolsStore.setIsShowLoader(false);
      }
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const successAfter = () => {
    props.close(true);
  };

  const checkIsChanged = newOrg => {
    const oldOrg = org.previousInfo || {};
    const changedFields = [];

    Object.entries(newOrg).forEach(([key, value]) => {
      const newValue = value || '';
      let oldValue = oldOrg[key] || '';
      if (isMoment(oldValue)) {
        oldValue = moment(oldValue).format(DATEFORMAT);
      }
      if (['id', 'orgContacts', 'decisionNumber'].includes(key)) {
        return;
      }
      if (isArray(newValue)) {
        const oldArray = oldOrg[key] || [];
        let isChanged = oldArray.length !== newValue.length;
        if (!isChanged) {
          newValue.forEach((newObj, index) => {
            const oldObj = oldArray[index] || {};
            if (isChangedData(newObj, oldObj)) {
              isChanged = true;
            }
          });
        }
        if (isChanged) {
          changedFields.push(key);
        }
      } else if (newValue !== oldValue) {
        changedFields.push(key);
      }
    });
    if (isEmptyObject(changedFields)) {
      message.warn('Өөрчлөгдсөн мэдээлэл байхгүй байна!');
      toolsStore.setIsShowLoader(false);
      return false;
    }
    changedData = {
      ...newOrg,
      id: null,
      rejectedFields: changedFields.join('&'),
    };
    return true;
  };

  function getInputStringBuilder(inputBuilder) {
    let collectedString = '';
    [...Array(inputBuilder.length)].forEach((row, index) => {
      const element = document.getElementById(`${inputBuilder.id}_${index}`);
      if (element) collectedString += element.value;
    });
    return collectedString;
  }

  async function saveOrgRequest(saveType, saveData) {
    let tmpReportGoups;
    if (saveData.reportGroupIds) {
      tmpReportGoups = saveData.reportGroupIds.map(reportGrp => ({
        id: reportGrp,
      }));
    }
    const orgSaveData = {
      wish: org.mainState.wish,
      id: saveData.id,
      uuid: saveData.uuid,
      name: saveData.name || org.mainState.name,
      certificateNo: getInputStringBuilder(certificateNo),
      registerNo: getInputStringBuilder(regNo),
      taxNo: getInputStringBuilder(taxNo),
      activityCode: saveData.activityCode || org.mainState.activityCode,
      refOrgTypeId: org.mainState.refOrgTypeId,
      activityContent: org.mainState.activityContent,
      refTypeId: saveData.refTypeId || null,
      refCentralBudgetGovernorId: saveData.refCentralBudgetGovernorId || null,
      directBudgetGovernor: saveData.directBudgetGovernor || null,
      budgetGovernor: org.mainState.budgetGovernor,
      reportGroupDTOS: tmpReportGoups,
      refCityId: saveData.refCityId,
      refDistrictId: saveData.refDistrictId,
      refKhorooId: saveData.refKhorooId,
      address: saveData.address || null,
      longitude: saveData.longitude || null,
      latitude: saveData.latitude || null,
      refLocalId: saveData.refLocalId, // Орон нутагт үйл ажиллагаагаа хэрхэн явуулдаг
      refStateEnterpriseId: saveData.refStateEnterpriseId, // Ангилал
      refLocalEnterpriseId: saveData.refLocalEnterpriseId, // Төрийн өмчит үйлдвэрийн газар-1, Орон нутгийн өмчит үйлдвэрийн газар-2
      participationStatePercent: saveData.participationStatePercent,
      participationLocalPercent: saveData.participationLocalPercent,
      participationOtherPercent: saveData.participationOtherPercent,
      participationOtherComment: saveData.participationOtherComment,
      decisionNumber: saveData.decisionNumber,
      isGovSpecialFund: org.mainState.isGovSpecialFund,
      refFundFormId: org.mainState.refFundFormId,
      refFundClassificationId: saveData.refFundClassificationId || null,
      // refOrgCategoryId: saveData.refOrgCategoryId,
      // refOrgCategorySubId: saveData.refOrgCategorySubId,
      // inactiveAt: saveData.inactiveAt,
      // inactiveDescription: saveData.inactiveDescription,
      // isStateLocalOwned: true,
      // refStateLocalTypeId: null,
      orgContacts: org.contactState,
      hasBranch: saveData.hasBranch || false,
      parentId: saveData.parentId || null,
      refTypeEnterpriseId: saveData.refTypeEnterpriseId || null,
    };

    if (org.mainState.refOrgTypeCode === ORGTYPE.firmNGO) {
      orgSaveData.budgetGovernor = '4'; // ААНБ бүртгүүлэх үед 4р бүртгүүлнэ
    }

    if (saveData.registeredAtMoment) {
      orgSaveData.registeredAt = saveData.registeredAtMoment.format(DATEFORMAT);
    } else if (org.mainState.registeredAt) {
      orgSaveData.registeredAt = moment(org.mainState.registeredAt).format(
        DATEFORMAT
      );
    }

    if (saveData.taxAtMoment) {
      orgSaveData.taxAt = saveData.taxAtMoment.format(DATEFORMAT);
    } else if (org.mainState.taxAt) {
      orgSaveData.taxAt = moment(org.mainState.taxAt).format(DATEFORMAT);
    }

    if (saveData.reportDataAtMoment) {
      orgSaveData.reportDataAt = saveData.reportDataAtMoment.format(DATEFORMAT);
    } else if (org.mainState.reportDataAt) {
      orgSaveData.reportDataAt = moment(org.mainState.reportDataAt).format(
        DATEFORMAT
      );
    }

    if (org.orgCallType === TYPE.REJECTED) {
      orgSaveData.isRecovery = true;
    }

    toolsStore.setIsShowLoader(true);

    // console.log('qwertyuio==================>>', JSON.stringify(orgSaveData));
    try {
      if (saveType === TYPE.NEW) {
        if (orgSaveData.id || org.orgCallType === TYPE.REJECTED) {
          await proceedOrganization(orgSaveData.id, orgSaveData);
          Success(successAfter, 'Хүсэлтийг амжилттай илгээлээ.');
        } else {
          await createOrganization(orgSaveData);
          Success(
            successAfter,
            'Хүсэлтийг амжилттай илгээлээ.',
            saveType === TYPE.NEW &&
              'Таны бүртгүүлсэн и-мэйл хаягт тань ажлын 5 хоногийн дотор хариу илгээх болно.'
          );
        }
      } else if (saveType === TYPE.DRAFT) {
        if (orgSaveData.id) {
          await updateTempOrg(orgSaveData.id, orgSaveData);
          Success(successAfter, 'Таны маягтыг түр хадгаллаа');
        } else {
          await createTemporaryOrg(orgSaveData);
          Success(successAfter, 'Таны маягтыг түр хадгаллаа');
        }
      } else if (saveType === TYPE.EDIT) {
        if (!checkIsChanged(orgSaveData)) {
          return;
        }
        setIsModalVisibleChangeInfo(true);
      } else if (saveType === TYPE.EDITBYADMIN) {
        if (!checkIsChanged(orgSaveData)) {
          return;
        }
        await updateOrganization(orgData.id, orgSaveData);
        Success(successAfter, 'Амжилттай хадгаллаа.');
      } else if (saveType === TYPE.ESTABLISH) {
        if (!checkIsChanged(orgSaveData)) {
          return;
        }
        props.close(true, changedData);
      } else if (saveType === TYPE.APPROVE) {
        await approveOrganization(orgData.id, orgSaveData);
        Success(successAfter, 'Байгууллагын хүсэлтийг амжилттай зөвшөөрлөө.');
      }
      toolsStore.setIsShowLoader(false);
    } catch (error) {
      toolsStore.setIsShowLoader(false);
    }
  }

  function save(saveType, saveData) {
    if (org.mainState.uuid || org.mainState.id) {
      saveData.id = org.mainState.id;
      saveData.uuid = org.mainState.uuid;
      saveOrgRequest(saveType, saveData);
    } else {
      toolsStore.setIsShowLoader(true);
      getGenerateUuid()
        .then(res => {
          saveData.uuid = res.data;
          saveOrgRequest(saveType, saveData);
        })
        .catch(err => {
          errorCatch(err);
          toolsStore.setIsShowLoader(false);
        });
    }
  }

  const saveDraft = () => {
    const saveData = org.form.getFieldsValue();
    save(TYPE.DRAFT, saveData);
  };

  const saveOrg = type => {
    if (
      [0, 7].includes(getInputStringBuilder(regNo).length) &&
      [0, 11].includes(getInputStringBuilder(taxNo).length)
    ) {
      org.form
        .validateFields()
        .then(values => {
          save(type, values);
        })
        .catch(() => {
          requireFieldFocus();
        });
    } else {
      message.warn(
        'Байгууллагын регистрийн дугаар эсвэл татвар төлөгчийн дугаар бүрэн оруулна уу!'
      );
    }
  };

  const checkReject = () => {
    if (isEmptyObject(org.rejectFields)) {
      message.warn('Сонгосон талбар байхгүй байна!');
      return;
    }
    setIsVisibleDescription(true);
  };

  const rejectOrg = description => {
    toolsStore.setIsShowLoader(true);
    let param = '';
    if (org.rejectFields) {
      Object.entries(org.rejectFields).forEach(([key]) => {
        if (param !== '') param += '&';
        param += key;
      });
    }
    const saveData = {
      rejectedDescription: description,
      rejectedFields: param,
    };
    rejectOrganization(orgData.id, saveData)
      .then(() => {
        setIsVisibleDescription(false);
        Success(successAfter, 'Амжилттай татгалзлаа.');
      })
      .finally(() => {
        toolsStore.setIsShowLoader(false);
      });
  };

  return (
    <div className={style.page}>
      <Row>
        <Col span={24}>
          {org.mainState.refOrgTypeId && (
            <Card className={style.container}>
              <Form
                form={org.form}
                {...layout}
                name="org"
                labelAlign="left"
                size="small"
              >
                <Header />
                <SectionGeneralInfo />
                <SectionFile />
                <SectionContact />
                <Footer />
                {isVisibleDescription && (
                  <Description
                    title="Та уг байгууллагыг татгалзахдаа итгэлтэй байна уу?"
                    visible={isVisibleDescription}
                    setVisible={setIsVisibleDescription}
                    save={rejectOrg}
                  />
                )}
              </Form>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );

  function Footer() {
    if (orgCallType === TYPE.DECIDE) {
      return (
        <div className={style.footer}>
          <Button
            size="middle"
            className="btn-light"
            disabled={toolsStore.isShowLoader}
            onClick={checkReject}
          >
            ТАТГАЛЗАХ
          </Button>
          <Button
            type="primary"
            size="middle"
            disabled={toolsStore.isShowLoader}
            onClick={() =>
              Confirm(
                () => saveOrg(TYPE.APPROVE),
                'Та зөвшөөрөхдөө итгэлтэй байна уу?',
                'Зөвшөөрснөөр тухайн байгууллага админы хамт системд бүртгэгдэх болно.'
              )
            }
          >
            ЗӨВШӨӨРӨХ
          </Button>
        </div>
      );
    }

    if (orgCallType === TYPE.EDITBYADMIN) {
      return (
        <div className={style.footer}>
          <Button
            type="primary"
            size="middle"
            disabled={toolsStore.isShowLoader}
            onClick={() =>
              Confirm(
                () => saveOrg(TYPE.EDITBYADMIN),
                'Та хадгалахдаа итгэлтэй байна уу?',
                'Тухайн байгууллагын мэдээлэл өөрчлөгдөж хадгалагдах болно.'
              )
            }
          >
            Хадгалах
          </Button>
        </div>
      );
    }

    if (orgCallType === TYPE.EDIT) {
      return (
        <div className={style.footer}>
          {isModalVisibleChangeInfo && (
            <ChangeInfo
              callType={TYPE.NEW}
              isModalVisibleChangeInfo={isModalVisibleChangeInfo}
              changedData={changedData}
              close={isSuccess => {
                setIsModalVisibleChangeInfo(false);
                if (isSuccess) {
                  successAfter();
                }
              }}
            />
          )}
          <Button
            size="middle"
            type="primary"
            disabled={toolsStore.isShowLoader}
            onClick={() => saveOrg(TYPE.EDIT)}
          >
            ХАДГАЛАХ
          </Button>
        </div>
      );
    }

    if (orgCallType === TYPE.ESTABLISH) {
      return (
        <div className={style.footer}>
          <Button
            size="middle"
            type="primary"
            disabled={toolsStore.isShowLoader}
            onClick={() => saveOrg(TYPE.ESTABLISH)}
          >
            ХАДГАЛАХ
          </Button>
        </div>
      );
    }

    if (
      orgCallType === TYPE.NEW ||
      orgCallType === TYPE.DRAFT ||
      orgCallType === TYPE.REJECTED
    ) {
      return (
        <div className={style.footer}>
          {(orgCallType === TYPE.NEW || orgCallType === TYPE.DRAFT) && (
            <Button
              size="middle"
              className="btn-light"
              disabled={toolsStore.isShowLoader}
              onClick={saveDraft}
            >
              ТҮР ХАДГАЛАХ
            </Button>
          )}
          <Button
            size="middle"
            type="primary"
            disabled={toolsStore.isShowLoader}
            onClick={() => saveOrg(TYPE.NEW)}
          >
            ХҮСЭЛТ ИЛГЭЭХ
          </Button>
        </div>
      );
    }

    return null;
  }
}
