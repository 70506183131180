export const FILTER_MODE = {
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  CONTAINS: 'contains',
  LT: 'lt',
  LTE: 'lte', // LESS THAN EQUALS
  GT: 'gt',
  GTE: 'gte', // GREATER_THAN_EQUALS
  EQUALS: 'equals',
  NOT: 'not',
  NOT_STARTS_WITH: 'notStartsWith',
  IN: 'in',
  DATE_BETWEEN: 'dateBetween',
};

export const FILTER_TYPE = {
  SELECT: 'select',
  DATE: 'date',
  DATESINGLE: 'datesingle',
};

export const SORT_ORDER = {
  ASC: 1,
  DESC: -1,
};

export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];
