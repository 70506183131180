import { CalendarOutlined } from '@ant-design/icons';
import React from 'react';
import { TYPE } from '../../../../constants/Constant';
import style from './style.module.scss';

export default function Item({ label, value, type }) {
  return (
    <div
      className={`${style.item} ${type === TYPE.REJECTED && style.rejected}`}
    >
      <span className={style.value}>
        {label.includes('огноо') && <CalendarOutlined />}
        {value}
      </span>
      <span className={style.label}>{label}</span>
    </div>
  );
}
