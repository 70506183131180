export const PAGESIZE = 25;

export const DATEFORMAT = 'YYYY-MM-DD';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const YEAR_FORMAT = 'YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const MSG = {
  SUCCESS: 'Амжилттай хадгалагдлаа.',
  SUCCESS_REQUEST: 'Хүсэлтийг амжилттай хадгаллаа.',
  SUCCESS_DEL: 'Амжилттай устлаа.',
  NOT_AUTHORIZED: 'Танд энэ үйлдлийг хийх эрх олгогдоогүй байна!',
  CYRILLIC: 'Кириллээр бичнэ үү!',
  MUST_FILL_FIELD: 'Энэ хэсгийг заавал бөглөнө үү!',
  CHOOSE_DELETE_DATA: 'Устгах өгөгдлөө сонгоно уу!',
};

export const USER_STEP = {
  ACTIVE: 1,
  APPROVED: 2,
  REJECTED: 3,
  TEMP: 4,
  EXPIRED: 5,
};

export const ORG_WISH = {
  CREATE: 1,
  CHANGE_INFO: 2,
  ESTABLISH: 3,
  REQUEST_FOR_END: 4,
};

export const ORG_WISHES = [
  {
    id: ORG_WISH.CREATE,
    name: 'Байгууллага шинээр бүртгүүлэх хүсэлт',
    tagColor: 'geekblue',
    bgColor: '#004a9f',
  },
  {
    id: ORG_WISH.CHANGE_INFO,
    name: 'Баталгаажсан мэдээлэлд өөрчлөлт оруулах хүсэлт',
    tagColor: 'cyan',
    bgColor: '#0ebfad',
  },
  {
    id: ORG_WISH.ESTABLISH,
    name: 'Өөрчлөн байгуулах хүсэлт',
    tagColor: 'orange',
    bgColor: '#39a6f9',
  },
  {
    id: ORG_WISH.REQUEST_FOR_END,
    name: 'Дуусгавар болгох хүсэлт',
    tagColor: 'red',
    bgColor: '#fd655d',
  },
];

export const ORG_STEP = {
  ACTIVE: 1,
  APPROVED: 2,
  REJECTED: 3,
  EXPIRED: 4,
};

export const ORG_STEPS = [
  { id: ORG_STEP.ACTIVE, name: 'Илгээсэн', color: 'yellow' },
  { id: ORG_STEP.APPROVED, name: 'Зөвшөөрсөн', color: 'green' },
  { id: ORG_STEP.REJECTED, name: 'Татгалзсан', color: 'red' },
  { id: ORG_STEP.EXPIRED, name: 'Хугацаа хэтэрсэн', color: 'orange' },
];

export const RE_ORG_TYPE = {
  MERGE: 1,
  SUPPLY: 2,
  DIVIDE: 3,
  ISOLATION: 4,
  CHANGE: 5,
};

export const RE_ORG_TYPES = [
  { id: RE_ORG_TYPE.MERGE, name: 'Нэгтгэх' },
  { id: RE_ORG_TYPE.SUPPLY, name: 'Нийлүүлэх' },
  { id: RE_ORG_TYPE.ISOLATION, name: 'Тусгаарлах' },
  { id: RE_ORG_TYPE.DIVIDE, name: 'Хуваах' },
  { id: RE_ORG_TYPE.CHANGE, name: 'Өөрчлөх' },
];

export const USER_TYPE = {
  ORG_ADMIN: 1,
  ORG_CEO: 2,
  ORG_ACCOUNTANT: 3,
};

export const USER_TYPES = [
  { id: USER_TYPE.ORG_ADMIN, name: 'Байгууллагын админ' },
  { id: USER_TYPE.ORG_CEO, name: 'Байгууллагын удирдлага' },
  { id: USER_TYPE.ORG_ACCOUNTANT, name: 'Байгууллагын нягтлан' },
];

export const IS_ACTIVES = [
  { id: true, name: 'Идэвхтэй' },
  { id: false, name: 'Идэвхгүй' },
];

export const TYPE = {
  NEW: 'new',
  DRAFT: 'draft',
  EDIT: 'edit',
  VIEW: 'view',
  DECIDE: 'decide',
  REJECTED: 'rejected',
  END: 'end',
  ESTABLISH: 'establish',
  APPROVE: 'approve',
  EDITBYADMIN: 'editbyadmin',
};

export const ORGTYPE = {
  EquityParticipationCompany: '1', // Төрийн болон орон нутгийн өмчит, өмчийн оролцоотой компани
  TusviinBaiguullaga: '2', // Төсвийн байгууллага
  FundedProject: '3', // Олон улсын санхүүжилттэй төсөл хөтөлбөр
  LocallyOwnedPlace: '4', // Төрийн болон орон нутгийн өмчит үйлдвэрийн газар
  UnRegisteredInfo: '5', // Бусад улсын бүртгэлд бүртгэлгүй мэдээлэл оруулах нэгж(Төрийн сан дээр данстай)
  SpecialFund: '6', // Засгийн газрын тусгай сан
  firmNGO: '7', // Аж ахуй нэгж, төрийн бус байгууллага, иргэн
};

export const ORG_SPECIAL_TYPE = {
  SpecialPublicInstitutions: 1, // Тусгай сан төсвийн байгууллага
  SpecialCountryCompany: 2, // Тусгай сан төр болон орон нутгийн өмчит оролцоотой компани
  SpecialCountryFactory: 3, // Тусгай сан төр болон орон нутгийн өмчит үйлдвэрийн газар
  SpecialAccountInformation: 4, // Тусгай сан дансны мэдээлэлтэй
};

export const ORG_TYPES_SCECIAL_FUNDS = {
  GovernmentSpecialFund: false, // Засгийн газрын тусгай сан
  LocalSpecialFund: true, // Орон нутгийн тусгай сан
};

export const ORG_TZ_TYPE = {
  TEZ: 1,
  TTZ: 2,
  TShZ: 3,
};

export const ORG_TZ_TYPE_MON = [
  { id: 1, name: 'ТЕЗ' },
  { id: 2, name: 'ТТЗ' },
  { id: 3, name: 'ТШЗ' },
];

// Санал хүсэлт
export const FFEDBACKTYPE = {
  COMPLAINT: 1, // гомдол
  FEEDBACK: 2, // Санал хүсэлт
};

export const TIMERECURRINGID = {
  everyMonth: 1, // Сар бүр
  eachSeason: 2, // улирал бүр
  aboutEach: 11, // тухай бүр
  everyYear: 6, // Жил бүр
  everyHhalfYear: 5, // хагас жил бүр
};

export const TEZTYPE = {
  typeCountry: 1, // Улсын ТЕЗ
  typeLocal: 2, // Орон нутаг ТЕЗ
};
export const TEZTYPE_LIST = [
  { id: TEZTYPE.typeCountry, name: 'Улсын ТЕЗ' },
  { id: TEZTYPE.typeLocal, name: 'Орон нутаг ТЕЗ' },
];

export const WHOM = {
  whomOrg: 1, // Байгууллага
  whomAudit: 2, // Аудит
  whomAdmin: 3, // Нэгдсэн Админ
};

export const SPECIAL_FUND_TYPE = {
  ACCUMULATION: 1, // хуримтлал, тогтворжуулалтын сан
  SOCIAL_SECURITY: 2, // Нийгмийн хамгааллын сан
  ANOTHER_SPECIAL_FUND_TYPE: 3, // Бусад засгийн газрын тусгай сан
};

export const SPECIAL_FUND_TYPE_LIST = [
  {
    id: SPECIAL_FUND_TYPE.ACCUMULATION,
    name: 'Хуримтлал, тогтворжуулалтын сан',
  },
  {
    id: SPECIAL_FUND_TYPE.SOCIAL_SECURITY,
    name: 'Нийгмийн хамгааллын сан',
  },
  {
    id: SPECIAL_FUND_TYPE.ANOTHER_SPECIAL_FUND_TYPE,
    name: 'Бусад засгийн газрын тусгай сан',
  },
];

export const FEEDBACKSTEPS = {
  ACTIVE: 1, // Идэвхтэй
  SOLVED: 2, // Шийдвэрлэсэн
  UNSOLVED: 3, // Шийдвэрлээгүй
};

export const USERROLE = {
  admin: 'admin',
  orgAdmin: 'orgAdmin',
  orgUser: 'orgUser',
  orgAudit: 'orgAudit',
  audit: 'audit',
  guest: 'guest',
  subAdmin: 'subAdmin',
};

export const NOTIFICATION_TYPE = {
  REQUEST_ORGANIZATION: 1,
  REQUEST_CUSTOMER: 2,
  REPORT_SETTINGS: 3,
  REPORT_SUBMIT: 4,
};
