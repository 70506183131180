import { isArray, isEmpty, isNil, isString } from 'lodash';
import moment from 'moment';
import { DATEFORMAT } from '../constants/Constant';
import { FILTER_MODE } from '../constants/LazyTable';

const getSearchByMany = (key, values) => {
  const searchStr = values.map(value => `${key}:${value}`).join(' OR ');
  return `(${searchStr})`;
};

const getParamSearch = paramSearch => {
  if (isEmpty(paramSearch)) {
    return '';
  }
  let searchStr = '';
  Object.entries(paramSearch).forEach(([key, value]) => {
    if (isNil(value)) {
      return;
    }
    if (!isEmpty(searchStr)) {
      searchStr += ' AND ';
    }
    if (isArray(value)) {
      searchStr += getSearchByMany(key, value);
    } else if (value.matchMode === FILTER_MODE.IN) {
      searchStr += getSearchByMany(key, value.value);
    } else if (value.matchMode === FILTER_MODE.CONTAINS) {
      searchStr += `${key}:'*${value.value}*'`;
    } else if (value.matchMode === FILTER_MODE.STARTS_WITH) {
      searchStr += `${key}:'${value.value}*'`;
    } else if (value.matchMode === FILTER_MODE.ENDS_WITH) {
      searchStr += `${key}:*${value.value}`;
    } else if (value.matchMode === FILTER_MODE.EQUALS) {
      searchStr += `${key}:${value.value}`;
    } else if (value.matchMode === FILTER_MODE.NOT) {
      searchStr += `${key}!${value.value}`;
    } else if (value.matchMode === FILTER_MODE.NOT_STARTS_WITH) {
      searchStr += `${key}!'${value.value}*'`;
    } else if (value.matchMode === FILTER_MODE.DATE_BETWEEN) {
      const beginDate = value.value[0];
      let endDate = value.value[1];
      if (beginDate && endDate) {
        const calcEndDate = moment(endDate).add(1, 'd').toDate();
        endDate = moment(calcEndDate).format(DATEFORMAT);
        searchStr += `${key}>${beginDate} AND ${key}<${endDate}`;
      }
    } else if (isString(value)) {
      searchStr += `${key}:'${value}'`;
    } else {
      searchStr += `${key}:${value}`;
    }
  });
  return searchStr;
};

/**
 *
 * @queryParams = {
 *    search: object,           {orgId:1590, 'user.isActive':true},
 *    sortField: string,        'createdAt',
 *    sortOrder: int,           SORT_ORDER.ASC OR SORT_ORDER.DESC,  bhgui ch bj bolno default:SORT_ORDER.DESC
 *    size: int,                25,
 *    page: int,                 1,
 *    ...etc
 * }
 */

export const getParamsQueryString = queryParams => {
  let paramsQueryString = '';
  if (!isEmpty(queryParams)) {
    const paramsObj = {};
    const search = getParamSearch(queryParams.search);
    if (search) {
      paramsObj.search = search;
    }
    if (queryParams.sortField) {
      paramsObj.sort = `${queryParams.sortField},${
        queryParams.sortOrder > 0 ? 'asc' : 'desc'
      }`;
    }
    Object.entries(queryParams).forEach(([key, value]) => {
      if (!(key === 'search' || key === 'sortField' || key === 'sortOrder')) {
        paramsObj[key] = value;
      }
    });
    const queryList = Object.entries(paramsObj).map(
      ([key, value]) => `${key}=${value}`
    );
    paramsQueryString = `?${queryList.join('&')}`;
  }
  return paramsQueryString;
};
