import React from 'react';
import { Typography } from 'antd';
import logs from '../../../../images/01.svg';

const { Title, Paragraph } = Typography;

export default function SlideOne() {
  return (
    <div className="slide-main">
      <div
        style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}
      >
        <img src={logs} alt="logs" height="160px" width="160px" />
      </div>
      <Title level={5} align="center">
        ШИЛЭН ДАНСНЫ НЭГДСЭН ЦАХИМ ХУУДАСНЫ БҮРТГЭЛИЙН МАЯГТЫГ ЯМАР БАЙГУУЛЛАГА
        БӨГЛӨХ ВЭ?
      </Title>
      <br />
      <Paragraph>
        &nbsp; &nbsp; Тус бүртгэлийн маягтыг Шилэн дансны тухай хуулиар
        зохицуулсан Шилэн дансны нэгдсэн цахим хуудас (ШДНЦХ)-д мэдээлэл оруулах
        үүрэг бүхий хуулийн этгээд, тэдгээрийн хараат, охин компани, салбар,
        төлөөлөгчийн газар (цаашид субъект) бөглөнө. Үүнд:
      </Paragraph>
      <br />
      <Paragraph>
        <span className="rcorners1">1</span>
        Төсвийн байгууллага, засгийн газрын тусгай сан
      </Paragraph>
      <Paragraph>
        <span className="rcorners1">2</span>
        Төрийн болон орон нутгийн өмчит үйлдвэрийн газар
      </Paragraph>
      <Paragraph style={{ display: 'flex' }}>
        <div className="rcorners1">3</div>
        <div>
          Төрийн болон орон нутгийн өмчийн оролцоотой компани, тэдгээрийн хараат
          болон охин компани
        </div>
      </Paragraph>
      <Paragraph style={{ display: 'flex' }}>
        <div className="rcorners1">4</div>
        <div>
          Засгийн газрын зээл, тусламжаар хэрэгжиж буй олон улсын төсөл,
          хөтөлбөрийг хэрэгжүүлэгч
        </div>
      </Paragraph>
      <Paragraph>
        <span className="rcorners1">5</span>Дээрх этгээдүүдэд холбогдох салбар,
        төлөөлөгчийн газар
      </Paragraph>
      <Paragraph>
        <span className="rcorners1">6</span>Хуулиар хориглоогүй дээр дурдсанаас
        бусад этгээд
      </Paragraph>
      <Paragraph style={{ display: 'flex', marginTop: '20px' }}>
        <div className="rcorners2">!</div>
        <div className="attention-text">
          Бүртгэлийн мэдээллийг үнэн зөв, цаг хугацаанд нь оруулсан эсэхэд
          төсвийн шууд захирагч, байгууллагын удирдлага хяналт тавьж ажиллана.
        </div>
      </Paragraph>
    </div>
  );
}
