/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography } from 'antd';
import progress from '../../../../images/progress.svg';
import Checked from '../../../../images/checked.svg';

const { Title, Paragraph } = Typography;

export default function SlideFour() {
  return (
    <div className="slide-main">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={progress} alt="logs" height="160px" width="160px" />
      </div>
      <Title level={5} align="center">
        ШДНЦХ-Д АНХ УДАА БҮРТГҮҮЛЭХ ҮЕ ШАТ
      </Title>
      <br />
      <Paragraph style={{ display: 'flex' }}>
        <div className="rcorners1">1</div>
        <div>
          Шинээр үүсгэн байгуулагдсаныг нотлох албан ёсны шийдвэр гарч, татвар
          төлөгчийн дугаар авснаас хойш ажлын&nbsp;
          <span style={{ fontWeight: 'bold' }}>3 хоногийн дотор</span>
          &nbsp;ШДНЦХ-д бүртгүүлэх маягтыг бөглөнө.
        </div>
      </Paragraph>
      <div style={{ marginLeft: '39px' }}>
        <Paragraph>Хавсаргах баримт бичиг :</Paragraph>
        <div style={{ marginLeft: '25px' }}>
          <Paragraph style={{ display: 'flex' }}>
            <div style={{ placeSelf: 'center', marginRight: '5px' }}>
              <img src={Checked} alt="logs" height="15px" width="15px" />
            </div>
            Хуулийн этгээдийн улсын бүртгэлийн гэрчилгээний хуулбар, ар өвөргүй
          </Paragraph>
          <Paragraph style={{ display: 'flex' }}>
            <div style={{ marginRight: '5px' }}>
              <img
                src={Checked}
                alt="logs"
                height="15px"
                width="15px"
                style={{ marginTop: '4px' }}
              />
            </div>
            Эрх бүхий байгууллагаас үүсгэн байгуулсан, болон өөрчлөн байгуулсан
            тухай тушаал, шийдвэр
          </Paragraph>
          <Paragraph style={{ display: 'flex' }}>
            <div style={{ placeSelf: 'center', marginRight: '5px' }}>
              <img src={Checked} alt="logs" height="15px" width="15px" />
            </div>
            Бусад холбогдох материал, гэрээ
          </Paragraph>
          <Paragraph style={{ display: 'flex' }}>
            <div style={{ marginRight: '5px' }}>
              <img
                src={Checked}
                alt="logs"
                height="15px"
                width="15px"
                style={{ marginTop: '4px' }}
              />
            </div>
            Олон улсын зээл тусламжийн төслийн хувьд зээлийн гэрээ, төслийн
            удирдамж, гарын авлага, адилтгах бусад материал
          </Paragraph>
        </div>
      </div>
      <Paragraph style={{ paddingTop: '10px', display: 'flex' }}>
        <div className="rcorners1">2</div>
        <div>
          Сангийн яамны ШДНЦХ-ны нэгдсэн админ “баталгаажуулах шаардлагатай”
          мэдээллийг үнэн зөв бөглөж, холбогдох материалыг хавсаргасан эсэхийг
          ажлын&nbsp;
          <span style={{ fontWeight: 'bold' }}>5 хоногийн дотор</span>
          &nbsp;нягтална.
        </div>
      </Paragraph>
      <Paragraph style={{ display: 'flex' }}>
        <div className="rcorners1">3</div>
        <div>
          Хүсэлт гаргасан хэрэглэгчийн "Холбоо барих" хэсэгт бүртгүүлсэн цахим
          хаягуудад "Мэдээлэл алдаатай" эсвэл "Бүртгэл амжилттай" гэсэн хариулт
          илгээгдэнэ.
        </div>
      </Paragraph>
    </div>
  );
}
