import React, { useState } from 'react';
import { Form } from 'antd';
import { ORG_TZ_TYPE, USER_TYPE } from '../constants/Constant';

const OrgContext = React.createContext({});

const defaultMainValues = {
  refOrgTypeId: null,
  refOrgTypeCode: null,
  refOrgTypeName: null,
  budgetGovernor: ORG_TZ_TYPE.TShZ,
  cityCode: null,
  refFundFormId: null,
  isGovSpecialFund: null,
  latitude: 47.91942717849257,
  longitude: 106.91784125122072,
};

const defaultContactList = [
  { type: USER_TYPE.ORG_ADMIN },
  { type: USER_TYPE.ORG_CEO },
];

export const OrgStore = props => {
  const [form] = Form.useForm();
  const rejectFields = {};
  const [mainState, setMainState] = useState(defaultMainValues);
  const [references, setReferences] = useState({
    cities: null,
    districts: null,
    khoroos: null,
  });
  const [fileState, setFileState] = useState();
  const [contactState, setContactState] = useState(defaultContactList);
  const [errorFields, setErrorFields] = useState();
  const [isVisibleOrgModal, setIsVisibleOrgModal] = useState();
  const [orgCallType, setOrgCallType] = useState();
  const [previousInfo, setPreviousInfo] = useState({});

  const resetValues = () => {
    form.resetFields();
    setMainState(defaultMainValues);
    setContactState(defaultContactList);
    setPreviousInfo({});
  };

  const setMain = value => {
    setMainState({ ...mainState, ...value });
  };

  const onCheckFields = (e, field) => {
    if (e.target.checked) {
      rejectFields[field] = e.target.checked;
    } else {
      delete rejectFields[field];
    }
  };

  return (
    <OrgContext.Provider
      value={{
        form,
        resetValues,
        mainState,
        setMain,
        fileState,
        setFileState,
        contactState,
        setContactState,
        errorFields,
        setErrorFields,
        references,
        setReferences,
        isVisibleOrgModal,
        setIsVisibleOrgModal,
        previousInfo,
        setPreviousInfo,
        orgCallType,
        setOrgCallType,
        rejectFields,
        onCheckFields,
      }}
    >
      {props.children}
    </OrgContext.Provider>
  );
};

export default OrgContext;
