import { USER_STEP } from '../constants/Constant';
import { getRequest, deleteService, postService, putService } from './Service';
import { getParamsQueryString } from './utils';

const serviceUserBaseUrl = '/gap-user-service';

const serviceUserUrl = `${serviceUserBaseUrl}/users`;
const serviceUserRole = `${serviceUserBaseUrl}/userRoles`;
const serviceUserService = `${serviceUserBaseUrl}/userServices`;
const serviceUserPermission = `${serviceUserBaseUrl}/userPermissions`;
const serviceUserController = `${serviceUserBaseUrl}/userControllers`;
const serviceUserMapping = `${serviceUserBaseUrl}/userMappings`;
const serviceUserListUrl = `${serviceUserBaseUrl}/userManages`;
const serviceUserListUrlSubAdmin = `${serviceUserListUrl}/findAllBySubAdmin`;
// const serviceUsersByToken = `${serviceUserListUrl}/getByToken`;
const serviceUsersByToken = `${serviceUserListUrl}/findByToken`;
const serviceUserRequest = `${serviceUserListUrl}/request`;
const serviceUserStatisticsByYear = `${serviceUserListUrl}/getQuantityGroupByYear`;
const serviceGetOrgAdmin = `${serviceUserListUrl}/getAdminUserByOrgId`;
const serviceAccessLog = `${serviceUserBaseUrl}/userAccessLogs`;
const serviceAccessLogout = `${serviceAccessLog}/logout`;
const serviceCountOfActiveUsers = `${serviceAccessLog}/getCountOfActiveUsers`;

// ---- User List ----
export const getOrgActiveUsers = async orgId => {
  const params = {
    search: {
      status: true,
      orgId,
      step: USER_STEP.APPROVED,
      'user.status': true,
    },
  };
  return await getRequest(serviceUserListUrl, params);
};

export const getUserRoles = async params =>
  await getRequest(serviceUserRole, params);

export const createUserRole = async values =>
  await postService(serviceUserRole, values);

export const updateUserRole = async (id, values) =>
  await putService(`${serviceUserRole}/${id}`, values);

export const getUserStatistics = async () =>
  await getRequest(serviceUserStatisticsByYear);

export const getUserById = async id =>
  await getRequest(`${serviceUserListUrl}/${id}`);

export const createNewUser = async values => {
  await postService(serviceUserListUrl, values);
};

export const updateUserInfo = async (id, values) =>
  await putService(`${serviceUserListUrl}/${id}`, values);

export const deleteUserManage = async id =>
  await deleteService(`${serviceUserListUrl}/${id}`);

export const updateUserRequest = async (id, values) =>
  await putService(`${serviceUserRequest}/${id}`, values);

export const getUserServices = async params =>
  await getRequest(serviceUserService, params);

export const getUserPermissions = async params =>
  await getRequest(serviceUserPermission, params);

export const createUserPermission = async values =>
  await postService(serviceUserPermission, values);

export const getUserControllers = async params =>
  await getRequest(serviceUserController, params);

export const getUserControllerById = async id =>
  await getRequest(`${serviceUserController}/${id}`);

export const createUserController = async values =>
  await postService(serviceUserController, values);

export const updateUserController = async (id, values) =>
  await putService(`${serviceUserController}/${id}`, values);

export const deleteUserController = async id =>
  await deleteService(`${serviceUserController}/${id}`);

export const getUserMappings = async params =>
  await getRequest(serviceUserMapping, params);

export const getUserMappingById = async id =>
  await getRequest(`${serviceUserMapping}/${id}`);

export const createUserMapping = async values =>
  await postService(serviceUserMapping, values);

export const updateUserMapping = async (id, values) =>
  await putService(`${serviceUserMapping}/${id}`, values);

export const deleteUserMapping = async id =>
  await deleteService(`${serviceUserMapping}/${id}`);

export const createAccessLog = async values =>
  await postService(serviceAccessLog, values);

export const accessLogout = async values => {
  const params = getParamsQueryString(values);
  await putService(`${serviceAccessLogout + params}`);
};

export const getCountOfActiveUsers = async () =>
  await getRequest(serviceCountOfActiveUsers);

export const getGetUsersByToken = async () =>
  await getRequest(serviceUsersByToken);

export const getGetOrgAdmin = async params =>
  await getRequest(serviceGetOrgAdmin, params);

export {
  serviceUserUrl,
  serviceUserRole,
  serviceUserListUrl,
  serviceUserListUrlSubAdmin,
  serviceAccessLog,
  serviceUserService,
};
