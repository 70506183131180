import React from 'react';
import { Col, Row } from 'antd';
import * as Fields from '../fields/Fields';

export default function EquityParticipationCompany() {
  return (
    <>
      <Row gutter={60}>
        <Col lg={12}>
          <Fields.RegisterNo />
          <Fields.CertificateNo />
          <Fields.TaxNo />
          <Fields.OrgName />
          <Fields.RegisteredAt />
          <Fields.TaxAt />
          <Fields.ActivityCode />
          <Fields.ActivityContent />
          <Fields.WhetherHasBranch />
          <Fields.ParentOrganization />
          <Fields.LocalActivity />
        </Col>
        <Col lg={12}>
          <Fields.ChooseTez />
          <Fields.InfoTypes />
          <Fields.OrgLocation />
        </Col>
      </Row>
      <div style={{ marginTop: '30px' }}>
        <Fields.EquityPartCompany
          title="Төрийн болон орон нутгийн өмчит компани"
          radioToriinUmch="Төрийн өмчит компани"
          radioOronNutag="Орон нутгийн өмчит компани"
        />
      </div>
    </>
  );
}
