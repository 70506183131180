import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { sortArray } from '../../tools/Tools';
import { useToolsStore } from '../../context/Tools';
import { flattenMenu } from '../utils';
import { MenuIcon } from './MenuIcon';

const { SubMenu } = Menu;

export default function Menus({ menus, collapsed }) {
  const history = useHistory();
  const { isOrgUser } = useToolsStore();
  const [selectedMenu, setSelectedMenu] = useState();

  const buildMenu = buildMenus =>
    sortArray(
      (buildMenus || []).filter(menu => menu.status === true),
      'priority'
    ).map(menu => {
      if (menu.menus?.length) {
        return (
          <SubMenu
            key={menu.code}
            icon={MenuIcon(menu.code)}
            title={collapsed ? '' : menu.name}
          >
            {buildMenu(menu.menus)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={menu.code} icon={MenuIcon(menu.code)}>
          <Link to={menu.path}>{menu.name}</Link>
        </Menu.Item>
      );
    });

  useEffect(() => {
    if (isEmpty(menus)) return;

    const flatList = flattenMenu(menus);
    if (isOrgUser) {
      const foundMenu = flatList.find(menu => menu.code === 'orgProfile');
      if (foundMenu) {
        history.push(foundMenu.path);
        setSelectedMenu(foundMenu);
        return;
      }
    }
    const foundMenu = flatList.find(
      menu => menu.path === window.location.pathname
    );
    if (foundMenu) {
      history.push(foundMenu.path);
      setSelectedMenu(foundMenu);
      return;
    }
    history.push('/dashboard');
    setSelectedMenu({ code: 'dashboard' });
  }, [menus]);

  if (!selectedMenu) {
    return null;
  }

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[selectedMenu?.code]}
      defaultOpenKeys={[selectedMenu?.parentCode]}
    >
      {buildMenu(menus)}
    </Menu>
  );
}
