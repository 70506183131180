import React from 'react';
import { StarOutlined, WarningOutlined, PlusOutlined } from '@ant-design/icons';
import style from './style.module.scss';

export default function IconRound({ type }) {
  const Icon = () => {
    switch (type) {
      case 0:
        return <PlusOutlined />;
      case 1:
        return <WarningOutlined />;
      default:
        return <StarOutlined />;
    }
  };

  const getStyle = () => {
    switch (type) {
      case 1:
        return style.dangerBg;
      case 2:
        return style.warningBg;
      case 3:
        return style.infoBg;
      case 4:
        return style.successBg;
      default:
        return style.successBg;
    }
  };

  return (
    <div className={`${style.iconRound} ${getStyle()} `}>
      <Icon />
    </div>
  );
}
