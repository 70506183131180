import React from 'react';
import { List, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isNil } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import IconRound from './IconRound';
import style from './style.module.scss';
import {
  DATEFORMAT,
  NOTIFICATION_TYPE,
  USERROLE,
} from '../../../../../constants/Constant';
import { notificationRead } from '../../../../../service/notificationService';
import { useToolsStore } from '../../../../../context/Tools';

export default function RequestList({
  totalCount,
  notificationData,
  setReload,
  setPageNumber,
}) {
  const history = useHistory();
  const { selectedUser } = useToolsStore();

  const convertNotificationsType = form => {
    switch (form) {
      case NOTIFICATION_TYPE.REQUEST_ORGANIZATION:
        return '/orgRequestDashboard';
      case NOTIFICATION_TYPE.REQUEST_CUSTOMER:
        if (selectedUser.role === USERROLE.orgAdmin) {
          return '/OrgInfo/OrgProfile';
        }
        return '/UserRole/Users';
      default:
        return form;
    }
  };

  const onChange = notification => {
    history.push('/');
    setTimeout(() =>
      history.replace({
        pathname: convertNotificationsType(notification.form),
        search: `?data=${notification.data}`,
        state: notification.data,
      })
    );
    if (!notification.isRead) {
      notificationRead(notification.id).then(result => {
        setReload(result);
      });
    }
  };

  const onChangePageNumber = pageNumber => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <div className={style.requestListDiv}>
      <List
        itemLayout="horizontal"
        loading={{
          size: 'large',
          spinning: isNil(notificationData),
          indicator: <LoadingOutlined />,
        }}
        pagination={{
          pageSize: 25,
          total: totalCount,
          onChange: onChangePageNumber,
        }}
        dataSource={notificationData}
        renderItem={notification => (
          <List.Item onClick={() => onChange(notification)}>
            <Space align="start" size={16} className={style.content}>
              <IconRound type={notification.type} />
              <div className={`space-between ${style.warningTime}`}>
                <div
                  style={{
                    width: '100%',
                    fontWeight: notification.isRead ? 'normal' : 600,
                    color: !notification.isRead && 'black',
                  }}
                >
                  {notification.description}
                </div>
                <p className={style.date}>
                  {moment(notification.createdAt).format(DATEFORMAT)}
                </p>
              </div>
            </Space>
          </List.Item>
        )}
      />
    </div>
  );
}
