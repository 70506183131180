import {
  ApartmentOutlined,
  BankOutlined,
  BlockOutlined,
  BorderOuterOutlined,
  BarChartOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  GlobalOutlined,
  InteractionOutlined,
  HomeOutlined,
  ScheduleOutlined,
  ProfileOutlined,
  ControlOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

export const MenuIcon = menuCode => {
  switch (menuCode.toLowerCase()) {
    case 'dashboard'.toLowerCase():
      return <BarChartOutlined />;
    case 'orgRequestDashboard'.toLowerCase():
      return <SolutionOutlined />;
    case 'reference'.toLowerCase():
      return <ApartmentOutlined />;
    case 'org'.toLowerCase():
      return <BankOutlined />;
    case 'userRole'.toLowerCase():
      return <UsergroupAddOutlined />;
    case 'orgInfo'.toLowerCase():
      return <HomeOutlined />;
    case 'orgProfile'.toLowerCase():
      return <ScheduleOutlined />;
    case 'orgRequest'.toLowerCase():
      return <ProfileOutlined />;
    case 'settings'.toLowerCase():
      return <BlockOutlined />;
    case 'reportFormSettings'.toLowerCase():
      return <SettingOutlined />;
    case 'reportOrganization'.toLowerCase():
      return <ControlOutlined />;
    case 'reportOrganizationData'.toLowerCase():
      return <EditOutlined />;
    case 'feedback'.toLowerCase():
      return <ExclamationCircleOutlined />;
    case 'helpPage'.toLowerCase():
      return <QuestionCircleOutlined />;
    case 'categoryList'.toLowerCase():
      return <QuestionOutlined />;
    case 'letter'.toLowerCase():
      return <MailOutlined />;
    case 'portalController'.toLowerCase():
      return <GlobalOutlined />;
    case 'integrationMatch'.toLowerCase():
      return <InteractionOutlined />;

    default:
      return <BorderOuterOutlined style={{ fontSize: 9 }} />;
  }
};
