import React from 'react';
import { Statistic } from 'antd';
import { useKeycloak } from '@react-keycloak/web';
import { TOKEN_DEADLINE } from '../../constants/SystemConstants';
import { logout } from '../../tools/Tools';
import style from './style.module.scss';

const { Countdown } = Statistic;

export default function Counter() {
  const { keycloak } = useKeycloak();

  const onFinishCountdown = () => {
    logout(keycloak);
  };

  return (
    <div className={style.counter}>
      <Countdown
        value={TOKEN_DEADLINE}
        onFinish={onFinishCountdown}
        format="mm минут ss секундын дараа системээс гарна!"
      />
    </div>
  );
}
