import { deleteService, getRequest } from './Service';

const serviceBaseUrl = '/gap-reference-service';

const serviceRefOrgTypes = `${serviceBaseUrl}/refOrgTypes`;
const serviceRefTBONUK = `${serviceBaseUrl}/refStateLocalTypes`;
const serviceRefTOUG = `${serviceBaseUrl}/refStateEnterprises`;
const serviceRefONOUG = `${serviceBaseUrl}/refLocalEnterprises`;
const serviceRefISIC = `${serviceBaseUrl}/refIsics`;
const serviceRefUlsTEZ = `${serviceBaseUrl}/refTypes`;
const serviceRefTTZ = `${serviceBaseUrl}/refCentralBudgetGovernors`;
const serviceRefOrgActivity = `${serviceBaseUrl}/refLocals`;
const serviceRefCity = `${serviceBaseUrl}/refCities`;
const serviceRefDistrict = `${serviceBaseUrl}/refDistricts`;
const serviceRefBagKhoroo = `${serviceBaseUrl}/refKhoroos`;
const serviceRefBank = `${serviceBaseUrl}/refBanks`;
const serviceRefBankType = `${serviceBaseUrl}/refBankTypes`;
const serviceRefBankCurrency = `${serviceBaseUrl}/refBankCurrencies`;
const serviceRefFundClassifications = `${serviceBaseUrl}/refFundClassifications`;
const serviceRefSelectableChoices = `${serviceBaseUrl}/refSelectableChoices`;
const serviceRefLetterTypes = `${serviceBaseUrl}/refLetterTypes`;
const serviceRefSelectableChoicesByParent = `${serviceRefSelectableChoices}/getAllParents`;
const serviceRefTypeEnterprises = `${serviceBaseUrl}/refTypeEnterprises`;

export const getRefOrgTypes = async params =>
  await getRequest(serviceRefOrgTypes, params);

export const getRefCities = async params =>
  await getRequest(serviceRefCity, params);

export const getRefDistricts = async params =>
  await getRequest(serviceRefDistrict, params);

export const getRefBagKhoroo = async params =>
  await getRequest(serviceRefBagKhoroo, params);

export const getRefType = async id =>
  await getRequest(`${serviceRefUlsTEZ}/${id}`);

export const getRefCentralBudgetGovernor = async params =>
  await getRequest(serviceRefTTZ, params);

export const getRefSelectableChoicesByParent = async params =>
  await getRequest(serviceRefSelectableChoicesByParent, params);

export const getRefSelectableChoices = async params =>
  await getRequest(serviceRefSelectableChoices, params);

export const deleteRefSelectableChoicesByParent = async deleteId =>
  await deleteService(serviceRefSelectableChoices, { id: deleteId });

export const getRefBanks = async params =>
  await getRequest(serviceRefBank, params);

export const getRefBankTypes = async params =>
  await getRequest(serviceRefBankType, params);

export const getRefBankCurrencies = async params =>
  await getRequest(serviceRefBankCurrency, params);

export const getRefTypeEnterprises = async params =>
  await getRequest(serviceRefTypeEnterprises, params);

export {
  serviceRefOrgTypes,
  serviceRefTBONUK,
  serviceRefTOUG,
  serviceRefONOUG,
  serviceRefISIC,
  serviceRefUlsTEZ,
  serviceRefTTZ,
  serviceRefOrgActivity,
  serviceRefCity,
  serviceRefDistrict,
  serviceRefBagKhoroo,
  serviceRefBank,
  serviceRefBankCurrency,
  serviceRefFundClassifications,
  serviceRefSelectableChoices,
  serviceRefLetterTypes,
};
