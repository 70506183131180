import React from 'react';
import { Button, Typography } from 'antd';
import Five from '../../../../images/05.svg';
import video from '../../../../images/video.svg';
import chatting from '../../../../images/chatting.svg';
import email from '../../../../images/email.svg';
import phone from '../../../../images/smartphone.svg';
import OrganizationCreate from '../../OrganizationCreate';

const { Title, Paragraph } = Typography;

export default function SlideFive(props) {
  const [isVisibleChooseOrg, setIsVisibleChooseOrg] = React.useState();

  const ShiftToRegister = () => {
    setIsVisibleChooseOrg(true);
    // props.close()
  };

  return (
    <div className="slide-main">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={Five} alt="loes" height="180px" width="180px" />
      </div>
      <Title level={5} align="center">
        БҮРТГЭЛИЙН МАЯГТ БӨГЛӨХТЭЙ ХОЛБООТОЙ ТЕХНИК, ТУСЛАЛЦААГ ДАРААХ
        СУВГУУДААС АВНА УУ
      </Title>
      <br />
      <div style={{ padding: '0 20px' }}>
        <Paragraph style={{ display: 'flex' }}>
          <img
            src={video}
            alt="loes"
            height="20px"
            width="20px"
            style={{ marginRight: '10px' }}
          />
          Видео заавар
        </Paragraph>
        <Paragraph style={{ display: 'flex' }}>
          <img
            src={chatting}
            alt="loes"
            height="20px"
            width="20px"
            style={{ marginRight: '10px' }}
          />
          Chatbot:
        </Paragraph>
        <Paragraph style={{ display: 'flex' }}>
          <img
            src={email}
            alt="loes"
            height="20px"
            width="20px"
            style={{ marginRight: '10px' }}
          />
          shilendans@mof.gov.mn
        </Paragraph>
        <Paragraph style={{ display: 'flex' }}>
          <img
            src={phone}
            alt="loes"
            height="20px"
            width="20px"
            style={{ marginRight: '10px' }}
          />
          71001111
        </Paragraph>
      </div>
      <div style={{ textAlign: 'center', margin: '50px 0' }}>
        <Button
          onClick={ShiftToRegister}
          variant="outlined"
          style={{
            height: '40px',
            borderRadius: '20px',
            backgroundColor: '#6A63F6',
            // #6A63F6
            // #3E8CF7
            color: '#FFF',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            Үргэлжлүүлэх
          </span>
        </Button>
      </div>
      <OrganizationCreate
        visible={isVisibleChooseOrg}
        close={isSuccess => {
          setIsVisibleChooseOrg(false);
          if (isSuccess) {
            props.close(isSuccess);
          }
        }}
      />
    </div>
  );
}
