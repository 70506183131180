import React from 'react';
import { Form } from 'antd';
import InputMask from 'react-input-mask';
import { PATTERN_PHONE } from '../constants/Pattern';

export default function PhoneNumber({
  name = 'phone',
  label = 'Утасны дугаар',
  pattern = PATTERN_PHONE,
  size,
  childProps,
}) {
  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign="left"
      rules={[
        { required: true, message: '' },
        { pattern, message: 'Утасны дугаар буруу байна' },
      ]}
    >
      <InputMask
        className={`ant-input ${size === 'small' ? 'ant-input-sm' : ''}`}
        mask="999999999999"
        maskChar=""
        {...childProps}
      />
    </Form.Item>
  );
}
