/* eslint-disable no-alert */
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';
import { getMapLocationByLatLng } from '../../service/Service';
import { DATEFORMAT, ORG_STEP, TYPE } from '../../constants/Constant';
import { getFiles } from '../../service/fileServices';

export const certificateNo = { id: 'certificateNo', length: 10 };
export const regNo = { id: 'regNo', length: 7 };
export const taxNo = { id: 'taxNo', length: 11 };

export const isRejectedField = (fieldName, org) =>
  org.mainState?.step === ORG_STEP.REJECTED &&
  !isEmpty(org.errorFields) &&
  org.errorFields.includes(fieldName);

export const isDisabled = (fieldName, org) =>
  // org.orgCallType === TYPE.DECIDE ||   // Нэгдсэн админ зөвшөөрөхдөө мэдээллээ засна гэсэн.
  org.orgCallType === TYPE.VIEW ||
  (org.orgCallType === TYPE.REJECTED && !isRejectedField(fieldName, org));

export function fillOrgInfo(orgContext, data) {
  if (!data) return;
  const params = {
    search: {
      status: true,
      parentUuid: data.uuid,
    },
  };
  getFiles(params).then(result => {
    const files = result.content || [];
    files.forEach((file, index) => {
      file.key = index;
    });
    orgContext.setFileState(files);
  });

  orgContext.setPreviousInfo(cloneDeep(data));

  if (data.step === ORG_STEP.REJECTED && data.rejectedFields) {
    const errorFields = (data.rejectedFields || '').split('&');
    orgContext.setErrorFields(errorFields);
  } else if (data.originId) {
    data.changedFields = data.rejectedFields;
  }

  if (data.orgContacts) {
    data.orgContacts.forEach((row, index) => {
      Object.entries(row).forEach(([key, value]) => {
        data[`${key}${index}`] = value;
      });
    });
  }
  const reportGroupDTOS = (data.reportGroupDTOS || []).filter(
    group => group.isActive
  );
  if (!isEmpty(reportGroupDTOS)) {
    data.reportGroupIds = reportGroupDTOS.map(reportGroup => reportGroup.id);
  }
  let contacts = data.orgContacts || [];
  if (isEmpty(contacts)) {
    // wish=1 s dusad zarim huseltuud deer hooson bgd bga tul origin org.contact-g set hiiv
    contacts = data.originOrg?.orgContacts || [];
  }
  if (data.isDraft) {
    orgContext.setContactState(contacts);
  } else {
    orgContext.setContactState(contacts.filter(contact => contact.status));
  }
  if (orgContext.orgCallType !== TYPE.VIEW) {
    if (data.registeredAt) {
      data.registeredAtMoment = moment(data.registeredAt, DATEFORMAT);
    }
    if (data.taxAt) {
      data.taxAtMoment = moment(data.taxAt, DATEFORMAT);
    }
    if (data.reportDataAt) {
      data.reportDataAtMoment = moment(data.reportDataAt, DATEFORMAT);
    }
    orgContext.form.setFieldsValue({ ...data });
  }
  orgContext.setMain({ ...data });
}

function HandelLocationError() {
  // switch (error.code) {
  //   case error.PERMISSION_DENIED:
  //     alert('User denied the request for Geolocation.');
  //     break;
  //   case error.POSITION_UNAVAILABLE:
  //     alert('Location information is unavailable.');
  //     break;
  //   case error.TIMEOUT:
  //     alert('The request to get user location timed out.');
  //     break;
  //   case error.UNKNOWN_ERROR:
  //   default:
  //     alert('An unknown error occurred.');
  //     break;
  // }
}

export function getCurrentPositionByMap(orgContext) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
      getMapLocationByLatLng(
        position.coords.latitude,
        position.coords.longitude
      )
        .then(data => {
          if (data.results.length > 2) {
            orgContext.setMain({
              address: data.results[2].formatted_address,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          }
        })
        .catch(error => alert(error));
    }, HandelLocationError);
  } else {
    alert('Geolocation is not supported by this browser.');
  }
}
