import React from 'react';
import { Typography } from 'antd';
import completed from '../../../../images/completed.svg';

const { Title, Paragraph } = Typography;

export default function SlideTwo() {
  return (
    <div className="slide-main">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={completed} alt="loes" height="180px" width="180px" />
      </div>
      <Title level={5} align="center">
        ЯМАР ТОХИОЛДОЛД МАЯГТЫГ ХӨТЛӨХ ВЭ?
      </Title>
      <br />
      <Paragraph
        style={{
          fontWeight: 'bold',
          margin: '16px 0',
        }}
      >
        Мэдээлэх үүрэг бүхий этгээд
      </Paragraph>
      <Paragraph>
        <span className="rcorners1">1</span> Шинээр үүсгэн байгуулагдсан эсхүл
        ШДНЦХ-д анх удаа бүртгүүлэхэд
      </Paragraph>
      <Paragraph>
        <span className="rcorners1">2</span> Өөрчлөн байгуулагдсан
      </Paragraph>
      <Paragraph>
        <span className="rcorners1">3</span> Үйл ажиллагаа нь дуусгавар болсон
      </Paragraph>
      <Paragraph>
        <span className="rcorners1">4</span> Бусад шалтгаанаар мэдээлэлд
        өөрчлөлт орсон
      </Paragraph>

      <Paragraph style={{ display: 'flex', marginTop: '20px' }}>
        <div className="rcorners2">!</div>
        <div className="attention-text">
          Мэдээлэх үүрэг бүхий этгээд татвар төлөгчийн дугаар авсан өдрөөс, үйл
          ажиллагаа нь дуусгавар болох хүртэлх хугацааны мэдээллийг ШДНЦХ-аар
          тайлагнах үүрэгтэй.
        </div>
      </Paragraph>
      <Paragraph style={{ display: 'flex' }}>
        <div className="rcorners2">!</div>
        <div className="attention-text">
          ШДНЦХ-аар тайлагнаагүй эсвэл хугацаа хоцорсон бол хуулийн дагуу
          хариуцлага хүлээх тул цахим системд нэн даруй бүртгүүлнэ үү.
        </div>
      </Paragraph>
    </div>
  );
}
