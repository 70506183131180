import React, { useState, useContext } from 'react';

export const ReportContext = React.createContext({});

export const useReportStore = () => useContext(ReportContext);

export default function ReportContextProvider({ children }) {
  const [reportRecurringList, setReportRecurringList] = useState([]);
  const [reportRecurringTypeList, setReportRecurringTypeList] = useState([]);
  const [reportGroupList, setReportGroupList] = useState([]);
  const [reportFormTypeList, setReportFormTypeList] = useState([]);
  const [selectedReportFormGroupList, setSelectedReportFormGroupList] =
    useState();
  const [isSaved, setIsSaved] = useState(true);
  const [dataSource, setDataSource] = useState([]);

  return (
    <ReportContext.Provider
      value={{
        reportRecurringTypeList,
        setReportRecurringTypeList,
        reportRecurringList,
        setReportRecurringList,
        reportGroupList,
        setReportGroupList,
        reportFormTypeList,
        setReportFormTypeList,
        selectedReportFormGroupList,
        setSelectedReportFormGroupList,
        isSaved,
        setIsSaved,
        dataSource,
        setDataSource,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}
