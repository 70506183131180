import React, { useState, useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import Loader from '../loader/Loader';
import {
  getNewLetterCount,
  getNotificationCount,
  getServerDate,
} from '../service/notificationService';
import { getIPAddress } from '../service/Service';
import { detectBrowser } from '../tools/Tools';
import { createAccessLog } from '../service/userService';

export const ToolsContext = React.createContext({});

export const useToolsStore = () => useContext(ToolsContext);

export default function ContextProvider({ children }) {
  const history = useHistory();
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [generalInfo, setGeneralInfo] = useState({
    userInfo: {},
    orgList: null,
  });
  const [selectedUser, setSelectedUser] = useState(); // {role,}
  const [org, setOrg] = useState({});
  const [letterCount, setLetterCount] = useState();
  const [notificationCount, setNotificationCount] = useState({});
  const [today, setToday] = useState(new Date());

  const refreshPage = () => {
    const currentPath = history.location.pathname;
    history.replace('/');
    setTimeout(() => {
      history.replace(currentPath || '/checkRegister');
    });
  };

  const isOrgUser = selectedUser?.role?.includes('org');

  const setAcceccLog = async () => {
    const log = {
      userRole: selectedUser.userRoleCode,
      orgId: selectedUser.orgId,
      ipAddress: await getIPAddress(),
      browser: detectBrowser(),
      userAgent: navigator.userAgent,
    };
    await createAccessLog(log);
  };

  const setNewLetterCount = () => {
    getNewLetterCount().then(result => {
      setLetterCount(result);
    });
  };

  const setNewNotificationCounts = cancelRef => {
    getNotificationCount(cancelRef).then(result => {
      setNotificationCount(result);
    });
  };

  const setterSelectedUser = user => {
    if (user) {
      localStorage.setItem('userRole', user.userRoleCode);
      if (user.orgId) {
        localStorage.setItem('orgId', user.orgId);
      }
      setSelectedUser({
        ...user,
        role: user?.userRoleCode,
      });
    } else {
      localStorage.removeItem('userRole');
      localStorage.removeItem('orgId');
      setSelectedUser(null);
    }
  };

  useEffect(() => {
    if (isEmpty(selectedUser)) return;
    setAcceccLog();
    setNewLetterCount();
    setNewNotificationCounts();
    getServerDate().then(serverDate => setToday(serverDate));
  }, [selectedUser]);

  return (
    <ToolsContext.Provider
      value={{
        isShowLoader,
        setIsShowLoader,
        generalInfo,
        setGeneralInfo,
        selectedUser,
        setterSelectedUser,
        org,
        setOrg,
        letterCount,
        setNewLetterCount,
        refreshPage,
        isOrgUser,
        notificationCount,
        setNewNotificationCounts,
        today,
        setToday,
      }}
    >
      {isShowLoader && <Loader />}
      {children}
    </ToolsContext.Provider>
  );
}
