import React, { useEffect, useState, useContext } from 'react';
import { Modal, Button, message } from 'antd';
import AutocompleteSelect from '../../components/Autocomplete';
import OrgContext from '../../context/OrgContext';
import OrganizationForm from './OrganizationForm';
import {
  ORG_SPECIAL_TYPE,
  ORG_TYPES_SCECIAL_FUNDS,
  TYPE,
} from '../../constants/Constant';
import './style.module.scss';
import { getRefOrgTypes } from '../../service/referenceServices';
import { Confirm } from '../../components/Confirm';

export default function OrganizationCreate(props) {
  const { visible } = props;
  const orgContext = useContext(OrgContext);
  const [orgTypes, setOrgTypes] = useState();

  useEffect(() => {
    const params = { search: { status: true } };
    getRefOrgTypes(params).then(result => {
      if (result) {
        setOrgTypes(result.content || []);
      }
    });
  }, []);

  const handleOk = e => {
    e.preventDefault();
    if (!orgContext.mainState.refOrgTypeId) {
      message.warning('Байгууллагын төрлөө сонгоно уу!');
      return;
    }
    orgContext.setIsVisibleOrgModal(true);
    props.close(false);
  };

  const onChangeOrgType = orgTypecode => {
    const selectedOrgType = orgTypes.find(z => z.code === orgTypecode);
    if (selectedOrgType) {
      orgContext.setMain({
        refOrgTypeId: selectedOrgType.id,
        refOrgTypeCode: orgTypecode,
        refOrgTypeName: selectedOrgType.name,
        isGovSpecialFund: ORG_TYPES_SCECIAL_FUNDS.LocalSpecialFund,
        refFundFormId: ORG_SPECIAL_TYPE.SpecialPublicInstitutions,
      });
    }
  };

  const handleCancel = () => {
    props.close(false);
  };

  const closeOrg = isSuccess => {
    orgContext.setIsVisibleOrgModal(false);
    orgContext.resetValues();
    if (isSuccess) {
      props.close(isSuccess);
    }
  };

  const onExit = () => {
    Confirm(
      () => closeOrg(false),
      'Та маягтаа хадгалахгүй гарахдаа итгэлтэй байна уу?'
    );
  };

  return (
    <>
      <Modal
        title="Байгууллагын төрөл"
        style={{ padding: '2px 2px' }}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" key="link" onClick={handleOk}>
            БҮРТГҮҮЛЭХ
          </Button>,
        ]}
      >
        <AutocompleteSelect
          key="orgTypeSelect"
          valuefield="code"
          value={orgContext.mainState.refOrgTypeCode}
          data={orgTypes}
          onChange={onChangeOrgType}
        />
      </Modal>
      {orgContext.isVisibleOrgModal && (
        <Modal
          className="org-modal"
          visible={orgContext.isVisibleOrgModal}
          onCancel={onExit}
          width="85%"
          height="100%"
          footer={false}
          destroyOnClose
        >
          <OrganizationForm
            orgCallType={TYPE.NEW}
            close={isSuccess => closeOrg(isSuccess)}
          />
        </Modal>
      )}
    </>
  );
}
