import React from 'react';
import { Col, Row } from 'antd';
import * as Fields from '../fields/Fields';

export default function FirmNGO() {
  return (
    <div>
      <Row gutter={60}>
        <Col lg={12}>
          <Fields.RegisterNo />
          <Fields.CertificateNo />
          <Fields.TaxNo />
          <Fields.OrgName />
          <Fields.RegisteredAt />
          <Fields.TaxAt />
          <Fields.ActivityCode />
          <Fields.ActivityContent />
        </Col>
        <Col lg={12}>
          <Fields.FirmNGOType />
          <Fields.InfoTypes />
          <Fields.OrgLocation />
        </Col>
      </Row>
    </div>
  );
}
