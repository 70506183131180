import React, { useState, useContext } from 'react';
import { ORG_WISH } from '../constants/Constant';

const OrgDashboardContext = React.createContext({});

export const useOrgDashboardStore = () => useContext(OrgDashboardContext);

export default function OrgDashboardContextProvider({ children }) {
  const [reloadCounter, setReloadCounter] = useState(true);
  const [selectedMainType, setSelectedMainType] = useState(ORG_WISH.CREATE);
  const [selectedType, setSelectedType] = useState('active');

  const reloadDashboard = () => {
    setReloadCounter(!reloadCounter);
  };

  return (
    <OrgDashboardContext.Provider
      value={{
        reloadCounter,
        reloadDashboard,
        selectedMainType,
        setSelectedMainType,
        selectedType,
        setSelectedType,
      }}
    >
      {children}
    </OrgDashboardContext.Provider>
  );
}
