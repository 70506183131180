import React, { useEffect } from 'react';
import { Modal, Button, Form, Input, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { MSG } from '../../constants/Constant';
import { requireFieldFocus } from '../../tools/Tools';
import FormItemCyrillic from '../FormItemCyrillic';
import { useToolsStore } from '../../context/Tools';

const { TextArea } = Input;

function Description(props) {
  const { isShowLoader } = useToolsStore();
  const { title, visible, value } = props;
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setVisible(false);
  };

  const save = () => {
    form
      .validateFields()
      .then(values => {
        props.save(values.description);
      })
      .catch(() => requireFieldFocus());
  };

  useEffect(() => {
    if (value) {
      form.setFieldsValue({ description: value });
    }
  }, []);

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <QuestionCircleOutlined
            style={{ color: '#faad14', fontSize: 20, marginRight: 12 }}
          />
          <span>{title}</span>
        </div>
      }
      visible={visible}
      closable={false}
      footer={false}
    >
      <Form form={form}>
        <FormItemCyrillic label="Тайлбар" name="description">
          <TextArea rows={3} placeholder={MSG.CYRILLIC} maxLength={500} />
        </FormItemCyrillic>
        <Form.Item style={{ textAlign: 'right' }}>
          <Space>
            <Button
              size="middle"
              type="secondary"
              disabled={isShowLoader}
              onClick={handleCancel}
            >
              Үгүй
            </Button>
            <Button
              size="middle"
              type="primary"
              disabled={isShowLoader}
              onClick={save}
            >
              Тийм
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Description;
