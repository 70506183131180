import React from 'react';
import { Button, Table, Input, Spin } from 'antd';
import { LoadingOutlined, PlusSquareFilled } from '@ant-design/icons';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import FileUpload from './FileUpload';

export default function FileArray(props) {
  const {
    title,
    fileArrayState,
    setFileArrayState,
    generateUuid,
    setGenerateUuid,
    isView,
    isHideAddBtn,
    elementId,
  } = props;

  const setKeyFileArray = () => {
    if (!fileArrayState) return;
    fileArrayState.forEach((row, index) => {
      row.key = index;
    });
    setFileArrayState([...fileArrayState]);
  };

  const adder = () => {
    fileArrayState.push({});
    setKeyFileArray();
  };

  const removeRow = row => {
    const index = fileArrayState.indexOf(row);
    if (index > -1) {
      fileArrayState.splice(index, 1);
      setKeyFileArray();
    }
  };

  const setter = () => {
    setFileArrayState([...fileArrayState]);
  };

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
      width: '3%',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Файлын тайлбар',
      dataIndex: 'description',
      key: 'description',
      width: '35%',
      render: (text, record, index) => (
        <Input
          key={index}
          value={text}
          disabled={record.uuid}
          onChange={event => {
            record.description = event.target.value;
            setFileArrayState([...fileArrayState]);
          }}
        />
      ),
    },
    {
      title: 'Файл',
      dataIndex: 'file',
      key: 'file',
      width: '57%',
      render: (text, record, index) => (
        <FileUpload
          key={`${(elementId || '') + index}`}
          generateUuid={generateUuid}
          isViewMode={isView}
          setGenerateUuid={setGenerateUuid}
          file={record}
          setter={setter}
          remove={() => removeRow(record)}
        />
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <div>{title}</div>
        {!isView && !isHideAddBtn && (
          <Button
            type="secondary"
            icon={<PlusSquareFilled />}
            onClick={() => adder()}
          >
            Нэмэх
          </Button>
        )}
      </div>
      <Table
        rowKey={record =>
          record.key === null || record.key === undefined
            ? record.id
            : record.key
        }
        columns={columns}
        dataSource={fileArrayState}
        bordered
        size="middle"
        pagination={false}
        locale={{
          emptyText: !fileArrayState ? (
            <Spin indicator={<LoadingOutlined />} />
          ) : (
            'Хавсаргасан файл байхгүй байна.'
          ),
        }}
      />
    </>
  );
}
